import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const AwardsEdit = ({ setEditBox, setInc, inc }) => {
  // Changing Value in LocalStorage
  const award = localStorage.getItem(defualtKey.awards)
    ? JSON.parse(localStorage.getItem(defualtKey.awards)).award
    : defaultValue.awards;

  // // Getting Value from Localtorage

  const handleawardChange = (e, i) => {
    let newSetAward = award;
    newSetAward[i] = [e.target.value, award[i][1]];
    localStorage.setItem(
      defualtKey.awards,
      JSON.stringify({ award: newSetAward })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteaward = (i) => {
    let newSetAward = award;
    newSetAward.splice(i, 1);
    localStorage.setItem(
      defualtKey.awards,
      JSON.stringify({ award: newSetAward })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Awards</h3>

      {award.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value}
              handleChange={(e) => {
                handleawardChange(e, index);
              }}
            />

            {award.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteaward(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default AwardsEdit;
