import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function TrainingsEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage
  const trainings = localStorage.getItem(defualtKey.trainings)
    ? JSON.parse(localStorage.getItem(defualtKey.trainings)).trainings
    : defaultValue.trainings;

  // // Getting Value from Localtorage

  //   trainingName

  const handleTrainingName = (e, i) => {
    let newSetTrainings = trainings;
    newSetTrainings[i] = {
      trainingName: e.target.value,
      trainingCategory: trainings[i].trainingCategory,
      trainingInstitute: trainings[i].trainingInstitute,
      trainingPeriod: trainings[i].trainingPeriod,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  //   trainingCategory

  const handleTrainingCategory = (e, i) => {
    let newSetTrainings = trainings;
    newSetTrainings[i] = {
      trainingName: trainings[i].trainingName,
      trainingCategory: e.target.value,
      trainingInstitute: trainings[i].trainingInstitute,
      trainingPeriod: trainings[i].trainingPeriod,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  //   trainingInstitute

  const handleTrainingInstitute = (e, i) => {
    let newSetTrainings = trainings;
    newSetTrainings[i] = {
      trainingName: trainings[i].trainingName,
      trainingCategory: trainings[i].trainingCategory,
      trainingInstitute: e.target.value,
      trainingPeriod: trainings[i].trainingPeriod,
    };
    setInc((parseInt(inc) + 1).toString());
  };
  //   trainingName

  const handleTrainingPeriod = (e, i) => {
    let newSetTrainings = trainings;
    newSetTrainings[i] = {
      trainingName: trainings[i].trainingName,
      trainingCategory: trainings[i].trainingCategory,
      trainingInstitute: e.target.value,
      trainingPeriod: trainings[i].trainingPeriod,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  //   Add Training

  const addTraining = () => {
    let newSetTrainings = trainings;
    newSetTrainings.push(defaultValue.skills[0]);
    localStorage.setItem(
      defualtKey.trainings,
      JSON.stringify({ trainings: newSetTrainings })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteTraining = (i) => {
    let newSetTrainings = trainings;
    newSetTrainings.splice(i, 1);
    localStorage.setItem(
      defualtKey.trainings,
      JSON.stringify({ trainings: newSetTrainings })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Trainings</h3>
      {trainings.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Training Name"
              type="text"
              defaultValue={value.trainingName}
              handleChange={(e) => {
                handleTrainingName(e, index);
              }}
            />
            <Input
              label="Training Category"
              type="text"
              defaultValue={value.trainingCategory}
              handleChange={(e) => {
                handleTrainingCategory(e, index);
              }}
            />
            <Input
              label="Institute Name"
              type="text"
              defaultValue={value.trainingInstitute}
              handleChange={(e) => {
                handleTrainingInstitute(e, index);
              }}
            />
            <Input
              label="Project Training Timing"
              type="text"
              defaultValue={value.trainingPeriod}
              handleChange={(e) => {
                handleTrainingPeriod(e, index);
              }}
            />

            {trainings.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteTraining(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {trainings.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addTraining}>
          Add Training
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
