import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function EducationEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage newSetEducation
  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  // // Getting Value from Localtorage
  const handleDegreeName = (e, i) => {
    let newSetEducation = education;
    newSetEducation[i] = {
      degreeName: e.target.value,
      collegeName: education[i].collegeName,
      degreeTiming: {
        startDate: education[i].degreeTiming.startDate,
        endDate: education[i].degreeTiming.endDate,
      },
    };
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCollegeName = (e, i) => {
    let newSetEducation = education;
    newSetEducation[i] = {
      degreeName: education[i].degreeName,
      collegeName: e.target.value,
      degreeTiming: {
        startDate: education[i].degreeTiming.startDate,
        endDate: education[i].degreeTiming.endDate,
      },
    };
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleStartDate = (e, i) => {
    let newSetEducation = education;
    newSetEducation[i] = {
      degreeName: education[i].degreeName,
      collegeName: education[i].collegeName,
      degreeTiming: {
        startDate: e.target.value,
        endDate: education[i].degreeTiming.endDate,
      },
    };
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleEndDate = (e, i) => {
    let newSetEducation = education;
    newSetEducation[i] = {
      degreeName: education[i].degreeName,
      collegeName: education[i].collegeName,
      degreeTiming: {
        startDate: education[i].degreeTiming.startDate,
        endDate: e.target.value,
      },
    };
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Add Education
  const addNewEducation = () => {
    let newSetEducation = education;
    newSetEducation.push(defaultValue.education[0]);
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete Education
  const deleteEducation = (i) => {
    let newSetEducation = education;
    newSetEducation.splice(i, 1);
    localStorage.setItem(
      defualtKey.education,
      JSON.stringify({ education: newSetEducation })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Education</h3>

      {education.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Degree Name"
              type="text"
              defaultValue={value.degreeName}
              handleChange={(e) => {
                handleDegreeName(e, index);
              }}
            />
            <Input
              label="School / University Name"
              type="text"
              defaultValue={value.collegeName}
              handleChange={(e) => {
                handleCollegeName(e, index);
              }}
            />
            <Input
              label="Start Date"
              type="text"
              defaultValue={value.degreeTiming.startDate}
              handleChange={(e) => {
                handleStartDate(e, index);
              }}
            />
            <Input
              label="End Date"
              type="text"
              defaultValue={value.degreeTiming.endDate}
              handleChange={(e) => {
                handleEndDate(e, index);
              }}
            />

            {education.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteEducation(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {education.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addNewEducation}>
          Add New Education
        </button>
      )}
      <Discard setEditBox={setEditBox} />
    </div>
  );
}
