import React from "react";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const SummaryEdit = ({ setInc, inc, setEditBox }) => {
  // Changing Value in LocalStorage newSetsummary
  const summary = JSON.parse(localStorage.getItem(defualtKey.summary))
    ? JSON.parse(localStorage.getItem(defualtKey.summary))
    : defaultValue.summary;

  // Getting Value from Localtorage

  const handleSummary = (e) => {
    localStorage.setItem(defualtKey.summary, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Summary</h3>
      <textarea
        defaultValue={summary}
        handleChange={handleSummary}
        cols="30"
        rows="10"
        className="form-control my-2"
      ></textarea>
      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default SummaryEdit;
