import React, { useState } from "react";

// Routes
import { BrowserRouter as Routers, Routes, Route } from "react-router-dom";

// importing components
import Edit from "./components/Edit";
import ErrorPage from "./components/ErrorPage";
import Home from "./components/Home";
import ResumeState from "./context/ResumeState";

export default function Router() {
  return (
    <>
      <ResumeState>
        <Routers>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Routes>
            <Route path="/edit/:resumeId" exact element={<Edit />} />
          </Routes>
        </Routers>
      </ResumeState>
    </>
  );
}
