import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ResumeContext from "../../context/ResumeContext";
import LoginModal from "./login/LoginModal";
import User from "./user.jpg";

export default function Navbar() {
  const context = useContext(ResumeContext);
  const { setIsLoggedIn, isLoggedIn } = context;
  const handleSignOut = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  return (
    <>
      <div className="fixed-top nav">
        <div className="container">
          <nav className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 ">
            <Link
              to="/"
              className="d-flex align-items-center col-md-4 mb-2 mb-md-0 text-dark text-decoration-none fs-3"
            >
              Resume Builder
            </Link>
            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
              <li>
                <a href="#" className="nav-link px-2 link-secondary">
                  Home
                </a>
              </li>
              <li>
                <a href="#templates" className="nav-link px-2 link-dark">
                  Templates
                </a>
              </li>

              <li>
                <a href="#about" className="nav-link px-2 link-dark">
                  About
                </a>
              </li>
            </ul>

            {!isLoggedIn ? (
              <div className="col-md-3 text-end">
                <button
                  type="button"
                  className="btn px-4 btn-outline-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                >
                  Login
                </button>
              </div>
            ) : (
              <div className="dropdown">
                <img
                  src={User}
                  alt="mdo"
                  width={40}
                  height={40}
                  type="button"
                  id="userInfo"
                  data-bs-toggle="dropdown"
                  className="rounded-circle border p-1  border-secondary"
                />

                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="userInfo"
                >
                  <li>
                    <a className="dropdown-item" href="#j">
                      Account
                    </a>
                  </li>

                  <li>
                    <p
                      className="dropdown-item mb-0"
                      role="button"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </nav>
        </div>
      </div>
      <div id="circle-2"></div>
      <LoginModal />
    </>
  );
}
