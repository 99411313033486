import React from "react";

// css
import "../../css/edit.css";

// img
import Pencil from "../../img/pencil.png";

export default function EditArrow({
  setEditBox,
  value,
  display,
  removeRef,
  top,
  right,
  width,
  height,
  arrowWidth,
  editArrowDisplay,
}) {
  return (
    <span
      className="edit-arrow-box"
      style={{
        display: editArrowDisplay,
        top: top,
        right: right,
        width: width,
        height: height,
      }}
      onClick={() => {
        setEditBox(value);
      }}
      ref={removeRef}
    >
      <img
        src={Pencil}
        alt=""
        className="edit-arrow"
        style={{ width: arrowWidth }}
      />
    </span>
  );
}
