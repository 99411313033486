import React from "react";
import ResumeImg from "../../img/resumes.png";
import Arrow from "../../img/arrow.svg";

export default function Header() {
  return (
    <div className="header-container" id="header">
      <div className="container">
        <div className="row featurette align-items-center justify-content-between">
          <div className="col-md-7 position-relative">
            <div>
              <h2 className="featurette-heading ">
                Let’s Build a<div>professional resume </div>
                <div> for free.</div>
              </h2>
              <div className="">
                <a href="#templates" className="btn btn-dark mt-3 ">
                  Get Started
                </a>
                {/* <button className="btn header-btn mt-3 ">Get Started</button> */}
              </div>
              <div className="arrow-box">
                <img src={Arrow} alt="" className="arrow" />
              </div>
            </div>
          </div>
          <div className="col-md-5 bg-img-section">
            <img
              src={ResumeImg}
              alt=""
              className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
            />
          </div>
        </div>
      </div>
      <div id="circle-1"></div>
    </div>
  );
}
