import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const ActivitiesEdit = ({ setEditBox, setInc, inc }) => {
  // Changing Value in LocalStorage
  const activity = localStorage.getItem(defualtKey.activity)
    ? JSON.parse(localStorage.getItem(defualtKey.activity)).activity
    : defaultValue.activity;

  // // Getting Value from Localtorage

  const handleActivityChange = (e, i) => {
    let newSetActivity = activity;
    newSetActivity[i] = [e.target.value, activity[i][1]];
    localStorage.setItem(
      defualtKey.activity,
      JSON.stringify({ activity: newSetActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  //   add activity
  const addActivity = () => {
    let newSetActivity = activity;
    newSetActivity.push(defaultValue.activity[0]);
    localStorage.setItem(
      defualtKey.activity,
      JSON.stringify({ activity: newSetActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  //   delete activity

  const deleteActivity = (i) => {
    let newSetActivity = activity;
    newSetActivity.splice(i, 1);
    localStorage.setItem(
      defualtKey.activity,
      JSON.stringify({ activity: newSetActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>activity</h3>

      {activity.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value}
              handleChange={(e) => {
                handleActivityChange(e, index);
              }}
            />

            {activity.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteActivity(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {activity.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addActivity}>
          Add Activity
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default ActivitiesEdit;
