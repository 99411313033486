import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function SkillInfo({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage
  const skillInfo = localStorage.getItem(defualtKey.skillInfo)
    ? JSON.parse(localStorage.getItem(defualtKey.skillInfo)).skillInfo
    : defaultValue.skillInfo;

  // // Getting Value from Localtorage

  //   handleSkillName

  const handleSkillName = (e, i) => {
    let newSetSkillInfo = skillInfo;
    newSetSkillInfo[i] = {
      skillName: e.target.value,
      skillDescription: skillInfo[i].skillDescription,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  //   handleSkillDescription

  const handleSkillDescription = (e, i) => {
    let newSetSkillInfo = skillInfo;
    newSetSkillInfo[i] = {
      skillName: skillInfo[i].skillName,
      skillDescription: e.target.value,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  //   Add Skill

  const addSkill = () => {
    let newSetSkillInfo = skillInfo;
    newSetSkillInfo.push(defaultValue.skills[0]);
    localStorage.setItem(
      defualtKey.skillInfo,
      JSON.stringify({ skillInfo: newSetSkillInfo })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // delete Skill

  const deleteSkill = (i) => {
    let newSetSkillInfo = skillInfo;
    newSetSkillInfo.splice(i, 1);
    localStorage.setItem(
      defualtKey.skillInfo,
      JSON.stringify({ skillInfo: newSetSkillInfo })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>skillInfo</h3>
      {skillInfo.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Training Name"
              type="text"
              defaultValue={value.trainingName}
              handleChange={(e) => {
                handleSkillName(e, index);
              }}
            />
            <Input
              label="Training Category"
              type="text"
              defaultValue={value.trainingCategory}
              handleChange={(e) => {
                handleSkillDescription(e, index);
              }}
            />

            {skillInfo.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteSkill(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {skillInfo.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addSkill}>
          Add Skill
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
