import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function SkillsTemplate({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage
  const skill = localStorage.getItem(defualtKey.skills)
    ? JSON.parse(localStorage.getItem(defualtKey.skills)).skill
    : defaultValue.skills;

  // // Getting Value from Localtorage

  const handleSkillChange = (e, i) => {
    let newSetSkill = skill;
    newSetSkill[i] = [e.target.value, skill[i][1]];
    localStorage.setItem(
      defualtKey.skills,
      JSON.stringify({ skill: newSetSkill })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleStarChange = (e, i) => {
    let newSetSkill = skill;
    newSetSkill[i] = [skill[i][0], e.target.value];
    localStorage.setItem(
      defualtKey.skills,
      JSON.stringify({ skill: newSetSkill })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Add Skill

  const addNewSkill = () => {
    let newSetSkill = skill;
    newSetSkill.push(defaultValue.skills[0]);
    localStorage.setItem(
      defualtKey.skills,
      JSON.stringify({ skill: newSetSkill })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete Skill

  const deleteSkill = (i) => {
    let newSetSkill = skill;
    newSetSkill.splice(i, 1);
    localStorage.setItem(
      defualtKey.skills,
      JSON.stringify({ skill: newSetSkill })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Skills</h3>

      {skill.map((value, index) => {
        return (
          <div>
            <Input
              type="text"
              defaultValue={value[0]}
              handleChange={(e) => {
                handleSkillChange(e, index);
              }}
            />
            <select
              // id="stars"
              name="stars"
              defaultValue={value[1]}
              className="form-control"
              onChange={(e) => {
                handleStarChange(e, index);
              }}
            >
              <option
                selected="true"
                disabled="disabled"
                className="form-control"
              >
                Choose Value
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>

            {skill.length > 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteSkill(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {skill.length > 4 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addNewSkill}>
          Add New Skill
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
