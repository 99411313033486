import React from "react";

export default function SkillStar({ star }) {
  let arrayChecked = ["", "", "", "", ""];
  for (let i = 0; i < star; i++) {
    arrayChecked[i] = "checked";
  }
  return (
    <div className="skill-star ms-5">
      {arrayChecked.map((value, id) => {
        if (value === "checked") {
          return <span className="fa fa-star checked" key={id}></span>;
        } else {
          return <span className="fa fa-star" key={id}></span>;
        }
      })}
    </div>
  );
}
