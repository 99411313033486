import React from "react";
import FileSvg from "../../img/svg/file.svg";
import PaintBox from "../../img/svg/paint.svg";

export default function Aside({
  handleCanvasResumeDisplay,
  handleSelectColourDisplay,
}) {
  return (
    <aside className="border edit-aside">
      <div className="aside-box">
        <div
          className="aside-img-box"
          onClick={handleCanvasResumeDisplay}
          role="button"
        >
          <img src={FileSvg} alt="file" width={"40%"} />
        </div>
        <div
          className="aside-img-box"
          role="button"
          onClick={handleSelectColourDisplay}
        >
          <img src={PaintBox} alt="paint-box" width={"40%"} />
        </div>
      </div>
    </aside>
  );
}
