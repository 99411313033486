import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function CertificationsEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage
  const certifications = localStorage.getItem(defualtKey.certifications)
    ? JSON.parse(localStorage.getItem(defualtKey.certifications)).certifications
    : defaultValue.certifications;

  // // Getting Value from Localtorage

  const handleTiming = (e, i) => {
    let newSetCertifications = certifications;
    newSetCertifications[i] = [e.target.value, certifications[i][1]];
    newSetCertifications[i] = {
      period: e.target.value,
      certificate: certifications[i].certificate,
    };
    localStorage.setItem(
      defualtKey.certifications,
      JSON.stringify({ certifications: newSetCertifications })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCertificate = (e, i) => {
    let newSetCertifications = certifications;
    newSetCertifications[i] = [e.target.value, certifications[i][1]];
    newSetCertifications[i] = {
      period: certifications[i].period,
      certificate: e.target.value,
    };
    localStorage.setItem(
      defualtKey.certifications,
      JSON.stringify({ certifications: newSetCertifications })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Add Certificate
  const addCertificate = () => {
    let newSetCertifications = certifications;
    newSetCertifications.push(defaultValue.education[0]);
    localStorage.setItem(
      defualtKey.certifications,
      JSON.stringify({ certifications: newSetCertifications })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // delete Cerficate
  const deleteCertificate = (i) => {
    let newSetCertifications = certifications;
    newSetCertifications.splice(i, 1);
    localStorage.setItem(
      defualtKey.certifications,
      JSON.stringify({ certifications: newSetCertifications })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Certificate </h3>

      {certifications.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Certificate Timing"
              type="text"
              defaultValue={value.period}
              handleChange={(e) => {
                handleTiming(e, index);
              }}
            />
            <Input
              label="Certificate Name"
              type="text"
              defaultValue={value.certificate}
              handleChange={(e) => {
                handleCertificate(e, index);
              }}
            />

            {certifications.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteCertificate(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {certifications.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addCertificate}>
          Add New Education
        </button>
      )}
      <Discard setEditBox={setEditBox} />
    </div>
  );
}
