import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const SkillNameEdit = ({ setEditBox, setInc, inc }) => {
  // Changing Value in LocalStorage
  const skillName = localStorage.getItem(defualtKey.skillName)
    ? JSON.parse(localStorage.getItem(defualtKey.skillName)).skillName
    : defaultValue.skillName;

  // // Getting Value from Localtorage

  const handleSkillNameChange = (e, i) => {
    let newSetSkillName = skillName;
    newSetSkillName[i] = [e.target.value, skillName[i][1]];
    localStorage.setItem(
      defualtKey.skillName,
      JSON.stringify({ skillName: newSetSkillName })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  //   Add Skill Name

  const addSkill = () => {
    let newSetSkillName = skillName;
    newSetSkillName.push(defaultValue.skillName[0]);
    localStorage.setItem(
      defualtKey.skillName,
      JSON.stringify({ skillName: newSetSkillName })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete Skill Name

  const deleteSkillName = (i) => {
    let newSetSkillName = skillName;
    newSetSkillName.splice(i, 1);
    localStorage.setItem(
      defualtKey.skillNames,
      JSON.stringify({ skillName: newSetSkillName })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Skill </h3>

      {skillName.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value}
              handleChange={(e) => {
                handleSkillNameChange(e, index);
              }}
            />

            {skillName.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteSkillName(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {skillName.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addSkill}>
          Add Skill
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default SkillNameEdit;
