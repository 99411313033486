import React from "react";

// css
import "../../css/resume/resume3.css";

import EditArrow from "./EditArrow";
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";
import ResumeInfo from "../../data/resumeInfo";

export default function Resume3({ setEditBox, display, editArrowDisplay }) {
  // Color
  const color = localStorage.getItem(ResumeInfo[2].color[0].name)
    ? localStorage.getItem(ResumeInfo[2].color[0].name)
    : ResumeInfo[2].color[0].value;

  // Header: name and contact

  const name = JSON.parse(localStorage.getItem(defualtKey.name))
    ? JSON.parse(localStorage.getItem(defualtKey.name))
    : defaultValue.name;

  const email = JSON.parse(localStorage.getItem(defualtKey.email))
    ? JSON.parse(localStorage.getItem(defualtKey.email))
    : defaultValue.email;

  const countryCode = JSON.parse(localStorage.getItem(defualtKey.countryCode))
    ? JSON.parse(localStorage.getItem(defualtKey.countryCode))
    : defaultValue.phone.countryCode;
  const phoneNumber = JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    ? JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    : defaultValue.phone.phoneNumber;

  const city = JSON.parse(localStorage.getItem(defualtKey.city))
    ? JSON.parse(localStorage.getItem(defualtKey.city))
    : defaultValue.address.city;
  const state = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.state;
  const country = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.country;

  const linkedin = JSON.parse(localStorage.getItem(defualtKey.linkedin))
    ? JSON.parse(localStorage.getItem(defualtKey.linkedin))
    : defaultValue.linkedin;

  //education
  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  //projects

  const projects = localStorage.getItem(defualtKey.projects)
    ? JSON.parse(localStorage.getItem(defualtKey.projects)).projects
    : defaultValue.projects;

  //trainings
  const trainings = localStorage.getItem(defualtKey.trainings)
    ? JSON.parse(localStorage.getItem(defualtKey.trainings)).trainings
    : defaultValue.trainings;

  //skillName

  const skillName = localStorage.getItem(defualtKey.skillName)
    ? JSON.parse(localStorage.getItem(defualtKey.skillName)).skillName
    : defaultValue.skillName;

  //courses

  const courses = localStorage.getItem(defualtKey.courses)
    ? JSON.parse(localStorage.getItem(defualtKey.courses)).courses
    : defaultValue.courses;

  //POR : Experience
  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  //Achievements : Awards

  const award = localStorage.getItem(defualtKey.awards)
    ? JSON.parse(localStorage.getItem(defualtKey.awards)).award
    : defaultValue.awards;

  //Activities

  const activities = localStorage.getItem(defualtKey.activities)
    ? JSON.parse(localStorage.getItem(defualtKey.activities)).activities
    : defaultValue.activities;

  // hobbies

  const hobbies = localStorage.getItem(defualtKey.hobbies)
    ? JSON.parse(localStorage.getItem(defualtKey.hobbies)).hobbies
    : defaultValue.hobbies;

  return (
    <div id="resume3">
      <header className="position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-5px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="1"
        />
        <h1 className="name bold-font">{name}</h1>
        {/* contact : Address, mobile , email and linkedIn  */}
        <div className="contact mt-4 row">
          <div className="social col-md-6">
            <i className="fa-solid fa-location-dot" />
            <span className="small ms-1">
              {city}, {state}, {country}
            </span>
          </div>
          <div className="social col-md-6">
            <i class="fa-solid fa-phone"></i>
            <span className="small ms-1">
              {countryCode} {phoneNumber}
            </span>
          </div>
          <div className="social col-md-6">
            <i className="fa-solid fa-envelope" />
            <span className="small ms-1"> {email}</span>
          </div>
          <div className="social col-md-6">
            <i className="fa-solid fa-mobile-screen" />
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <small className="small ms-1">{linkedin}</small>
            </a>
          </div>
        </div>
      </header>
      <div className="row mt-3">
        <div className="left-side col-7">
          {/* education  */}
          <section id="education-container" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="4"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              EDUCATION
            </h1>
            <div id="education">
              {education.map((edu, id) => {
                return (
                  <div className="education-box mb-3" key={id}>
                    <div className="education-subject small">
                      {edu.degreeName}
                    </div>
                    <h5
                      className="education-college my-2 "
                      style={{ color: color }}
                    >
                      {edu.collegeName}
                    </h5>
                    <div className="education-date mb-1">
                      <i className="fa-solid fa-calendar me-2" />
                      <span>
                        {edu.degreeTiming ? edu.degreeTiming.startDate : ""}
                      </span>
                      <span className="mx-1">-</span>
                      <span>
                        {edu.degreeTiming ? edu.degreeTiming.endDate : ""}
                      </span>
                      <li className="small">82%</li>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
          {/* projects  */}
          <section id="projects-container" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="9"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              PROJECTS
            </h1>
            <div id="projects">
              {projects.map((project, id) => {
                return (
                  <div className="project-box mb-3" key={id}>
                    <h5 className="second-heading bold-font">
                      {project.projectName}
                    </h5>
                    <li className="project-descrption">
                      {project.projectDescription}
                    </li>
                  </div>
                );
              })}
            </div>
          </section>
          {/* trainings  */}
          <section id="trainings-container" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="11"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              TRAININGS
            </h1>
            <div id="trainings">
              {trainings.map((training, id) => {
                return (
                  <div className="trainings-box mb-3" key={id}>
                    <div className="training-name small">
                      {training.trainingName}, {training.trainingCategory}
                    </div>
                    <h5 className="training-institute" style={{ color: color }}>
                      {training.trainingInstitute}
                    </h5>
                    <p className="training-period">
                      <i className="fa-solid fa-calendar me-2" />{" "}
                      {training.trainingPeriod}
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
        {/* RIGHT SIDE  */}
        <div className="right-side col-5">
          {/* skills  */}
          <section id="skills-container" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="12"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              SKILLS
            </h1>
            <div id="skills" className="row">
              {skillName.map((skill, id) => {
                return (
                  <button
                    className="btn btn-sm btn-secondary col-md-4 mb-2 me-1 rounded"
                    key={id}
                  >
                    {skill}
                  </button>
                );
              })}
            </div>
          </section>
          {/* courses  */}
          <section id="courses-container" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="13"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              COURSES
            </h1>
            <ul id="courses">
              {courses.map((course, id) => {
                return <li key={id}>{course}</li>;
              })}
            </ul>
          </section>
          {/* experience  */}
          <section id="experience-section" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="3"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              EXPERIENCE
            </h1>
            <div id="experience">
              {experience.map((exp, id) => {
                return (
                  <div className="experience-box mb-3" key={id}>
                    <h5 className="job-company second-heading bold-font">
                      {exp.jobRole}
                    </h5>
                    <p className="mb-1">{exp.companyName}</p>
                    <div className=" job-date small text-muted">
                      <span>
                        {exp.jobTiming ? exp.jobTiming.startDate : ""}
                      </span>
                      <span className="mx-1">-</span>
                      <span>{exp.jobTiming ? exp.jobTiming.endDate : ""}</span>
                    </div>
                    <p className="job-desc small">{exp.jobDescription}</p>
                  </div>
                );
              })}
            </div>
          </section>
          {/* achievements  */}
          <section id="achievements-section" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="7"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              ACHIEVEMENTS
            </h1>

            <ul id="achievements">
              {award.map((award, id) => {
                return <li key={id}>{award}</li>;
              })}
            </ul>
          </section>
          {/* activities  */}
          <section id="activities-section" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="10"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              ACTIVITIES
            </h1>
            <ul id="activities">
              {activities.map((activity, id) => {
                return <li key={id}>{activity}</li>;
              })}
            </ul>
          </section>
          {/* hobbies */}
          <section id="hobbies-section" className="position-relative">
            <EditArrow
              editArrowDisplay={editArrowDisplay}
              height="20px"
              width="20px"
              arrowWidth="12px"
              top="-13px"
              right="-9px"
              display={display}
              className="header-arrow"
              setEditBox={setEditBox}
              value="14"
            />
            <h1
              className="resume3-heading bold-font"
              style={{ color: color, borderBottom: `3px solid ${color}` }}
            >
              HOBBIES
            </h1>
            <ul id="hobbies">
              {hobbies.map((hobby, id) => {
                return <li key={id}>{hobby}</li>;
              })}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
