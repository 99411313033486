module.exports = {
  name: "Name",
  role: "Role",
  email: "Email",
  countryCode: "Country Code",
  phoneNumber: "Phone Number",
  city: "City",
  state: "State",
  country: "Country",
  linkedin: "LinkedIn",
  summary: "Summary",
  skills: "Skills",
  experience: "Experience",
  education: "Education",
  extraActivites: "Extra Activities",
  languages: "Languages",
  awards: "Awards",
  projects: "Projects",
  trainings: "Trainings",
  skillName: "SkillName",
  courses: "Courses",
  hobbies: "Hobbies",
  skillInfo: "Skill Info",
  software: "Software",
  certifications: "Certifications",
};
