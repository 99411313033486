import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function LanguageTemplate({ setInc, inc, width, setEditBox }) {
  // Changing Value in LocalStorage

  const language = localStorage.getItem(defualtKey.languages)
    ? JSON.parse(localStorage.getItem(defualtKey.languages)).language
    : defaultValue.languages;

  // Getting Value from Localtorage

  const handleLanguageChange = (e, i) => {
    let newSetLanguage = language;
    newSetLanguage[i] = [e.target.value, language[i][1]];
    localStorage.setItem(
      defualtKey.languages,
      JSON.stringify({ language: newSetLanguage })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleStarChange = (e, i) => {
    let newSetLanguage = language;
    newSetLanguage[i] = [language[i][0], e.target.value];
    localStorage.setItem(
      defualtKey.languages,
      JSON.stringify({ language: newSetLanguage })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteLanguage = (i) => {
    let newSetLanguage = language;
    newSetLanguage.splice(i, 1);
    localStorage.setItem(
      defualtKey.languages,
      JSON.stringify({ language: newSetLanguage })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Languages</h3>

      {language.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value[0]}
              handleChange={(e) => {
                handleLanguageChange(e, index);
              }}
            />
            <select
              name="stars"
              defaultValue={value[1]}
              className="form-control"
              onChange={(e) => {
                handleStarChange(e, index);
              }}
            >
              <option
                selected="true"
                disabled="disabled"
                className="form-control"
              >
                Choose Value
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>

            {language.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteLanguage(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
