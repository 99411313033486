import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function ProjectEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage newSetExperience
  const projects = localStorage.getItem(defualtKey.projects)
    ? localStorage.getItem(defualtKey.projects)
    : defaultValue.projects;

  // // Getting Value from Localtorage

  const handleProjectName = (e, i) => {
    let newSetProjects = projects;
    newSetProjects[i] = {
      projectName: e.target.value,
      projectDescription: projects[i].projectDescription,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  const handleProjectDescription = (e, i) => {
    let newSetProjects = projects;
    newSetProjects[i] = {
      projectName: projects[i].projectName,
      projectDescription: e.target.value,
    };
    setInc((parseInt(inc) + 1).toString());
  };

  const addProject = () => {
    let newSetProjects = projects;
    newSetProjects.push(defaultValue.skills[0]);
    localStorage.setItem(
      defualtKey.projects,
      JSON.stringify({ projects: newSetProjects })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteProject = (i) => {
    let newSetProjects = projects;
    newSetProjects.splice(i, 1);
    localStorage.setItem(
      defualtKey.projects,
      JSON.stringify({ projects: newSetProjects })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Projects</h3>
      {projects.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Project Name"
              type="text"
              defaultValue={value.projectName}
              handleChange={(e) => {
                handleProjectName(e, index);
              }}
            />
            <div className="mb-3">
              <label htmlFor="jobdesc" className="form-label">
                Project Description
              </label>
              <textarea
                cols="30"
                rows="4"
                className="form-control"
                defaultValue={value.projectDescription}
                onChange={(e) => {
                  handleProjectDescription(e, index);
                }}
              ></textarea>
            </div>

            {projects.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteProject(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {projects.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addProject}>
          Add Project
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
