import React from "react";
import Input from "./Input";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";
import Discard from "./Discard";

const HeaderEdit = ({ setInc, inc, setEditBox }) => {
  const handleName = (e) => {
    localStorage.setItem(defualtKey.name, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleRole = (e) => {
    localStorage.setItem(defualtKey.role, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleEmail = (e) => {
    localStorage.setItem(defualtKey.email, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCountryCode = (e) => {
    localStorage.setItem(
      defualtKey.countryCode,
      JSON.stringify(e.target.value)
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handlePhoneNumber = (e) => {
    localStorage.setItem(
      defualtKey.phoneNumber,
      JSON.stringify(e.target.value)
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCity = (e) => {
    localStorage.setItem(defualtKey.city, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleState = (e) => {
    localStorage.setItem(defualtKey.state, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCountry = (e) => {
    localStorage.setItem(defualtKey.country, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  const handleLinkedIn = (e) => {
    localStorage.setItem(defualtKey.linkedin, JSON.stringify(e.target.value));
    setInc((parseInt(inc) + 1).toString());
  };

  // Getting Value from Localtorage

  const localName = JSON.parse(localStorage.getItem(defualtKey.name));
  const localRole = JSON.parse(localStorage.getItem(defualtKey.role));
  const localEmail = JSON.parse(localStorage.getItem(defualtKey.email));
  const localCountryCode = JSON.parse(
    localStorage.getItem(defualtKey.countryCode)
  );
  const localPhoneNumber = JSON.parse(
    localStorage.getItem(defualtKey.phoneNumber)
  );
  const localCity = JSON.parse(localStorage.getItem(defualtKey.city));
  const localState = JSON.parse(localStorage.getItem(defualtKey.state));
  const localCountry = JSON.parse(localStorage.getItem(defualtKey.country));
  const localLinkedIn = JSON.parse(localStorage.getItem(defualtKey.linkedin));

  return (
    <div className="p-4  bg-white">
      <h1>Edit Section </h1>
      <Input
        label="Full Name"
        type="text"
        defaultValue={localName ? localName : defaultValue.name}
        handleChange={handleName}
      />
      <Input
        label="Job Role"
        type="text"
        defaultValue={localRole ? localRole : defaultValue.role}
        handleChange={handleRole}
      />
      <Input
        label="Email"
        type="email"
        defaultValue={localEmail ? localEmail : defaultValue.email}
        handleChange={handleEmail}
      />
      <label htmlFor="phonenumber" className="mb-0">
        Phone Number:
      </label>
      <span className="d-flex justify-content-between">
        <Input
          width="10%"
          defaultValue={
            localCountryCode ? localCountryCode : defaultValue.phone.countryCode
          }
          handleChange={handleCountryCode}
        />

        <Input
          width="85%"
          type="number"
          defaultValue={
            localPhoneNumber ? localPhoneNumber : defaultValue.phone.phoneNumber
          }
          handleChange={handlePhoneNumber}
        />
      </span>
      <label htmlFor="address">Address: </label>
      <Input
        label="City"
        type="text"
        defaultValue={localCity ? localCity : defaultValue.address.city}
        handleChange={handleCity}
      />
      <Input
        label="State"
        type="text"
        defaultValue={localState ? localState : defaultValue.address.state}
        handleChange={handleState}
      />
      <Input
        label="Country"
        type="text"
        defaultValue={
          localCountry ? localCountry : defaultValue.address.country
        }
        handleChange={handleCountry}
      />
      <Input
        label="LinkedIn"
        type="text"
        defaultValue={localLinkedIn ? localLinkedIn : defaultValue.linkedin}
        handleChange={handleLinkedIn}
      />
      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default HeaderEdit;
