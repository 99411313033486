import React from "react";
import FooterImg from "../../img/logo.png";

export default function Footer() {
  return (
    <footer id="about">
      <div className="container pt-3">
        <div className="row justify-content-between">
          <div className="col-12 col-md-5">
            <p>Powered By</p>
            <a
              href="http://softcolon.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={FooterImg} alt="softcolon.com" className="footerImg" />
            </a>
          </div>
          <div className="col-12 col-md-5">
            <p>Address: </p>
            <p className="address">
              307 Angel Arcade Opp National Handloom, Viratnagar Road, Sardar
              Patel Ring Rd, Ahmedabad, Gujarat 382350
            </p>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-6">
            <p className="">
              Copyright &copy;
              <a
                href="http://softcolon.com"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 text-white text-decoration-none"
              >
                softcolon.com
              </a>
              2022
            </p>
          </div>
          <div className="col-md-5">
            <p className="d-flex justify-content-between">
              <span>Terms and condition</span> <span>Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
