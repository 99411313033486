import React from "react";

export default function Discard({ setEditBox }) {
  const handleClose = () => {
    setEditBox("0");
  };

  return (
    <div className="d-grid">
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={handleClose}
      >
        Close
      </button>
    </div>
  );
}
