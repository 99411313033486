import React from "react";

export default function Input({
  label,
  type,
  defaultValue,
  handleChange,
  width,
}) {
  return (
    <div className="mb-3" style={{ width: width }}>
      <label className="form-label">{label}</label>
      <input
        type={type}
        className="form-control"
        onChange={handleChange}
        defaultValue={defaultValue}
      />
    </div>
  );
}
