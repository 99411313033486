import React from "react";

// css
import "../../css/resume/resume4.css";

import EditArrow from "./EditArrow";
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";
import SkillCircle from "./SkillCircle";
import ResumeInfo from "../../data/resumeInfo";

export default function Resume4({ setEditBox, display, editArrowDisplay }) {
  // Color
  const color = localStorage.getItem(ResumeInfo[3].color[0].name)
    ? localStorage.getItem(ResumeInfo[3].color[0].name)
    : ResumeInfo[3].color[0].value;
  // Header: name, role and contact

  const name = JSON.parse(localStorage.getItem(defualtKey.name))
    ? JSON.parse(localStorage.getItem(defualtKey.name))
    : defaultValue.name;

  const role = JSON.parse(localStorage.getItem(defualtKey.role))
    ? JSON.parse(localStorage.getItem(defualtKey.role))
    : defaultValue.role;

  const email = JSON.parse(localStorage.getItem(defualtKey.email))
    ? JSON.parse(localStorage.getItem(defualtKey.email))
    : defaultValue.email;

  const countryCode = JSON.parse(localStorage.getItem(defualtKey.countryCode))
    ? JSON.parse(localStorage.getItem(defualtKey.countryCode))
    : defaultValue.phone.countryCode;
  const phoneNumber = JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    ? JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    : defaultValue.phone.phoneNumber;

  const city = JSON.parse(localStorage.getItem(defualtKey.city))
    ? JSON.parse(localStorage.getItem(defualtKey.city))
    : defaultValue.address.city;
  const state = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.state;
  const country = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.country;

  const linkedin = JSON.parse(localStorage.getItem(defualtKey.linkedin))
    ? JSON.parse(localStorage.getItem(defualtKey.linkedin))
    : defaultValue.linkedin;

  //summary
  const summary = JSON.parse(localStorage.getItem(defualtKey.summary))
    ? JSON.parse(localStorage.getItem(defualtKey.summary))
    : defaultValue.summary;

  //skills

  const skill = localStorage.getItem(defualtKey.skills)
    ? JSON.parse(localStorage.getItem(defualtKey.skills)).skill
    : defaultValue.skills;

  //experience

  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  //education
  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  // extraActivity

  const extraActivity = localStorage.getItem(defualtKey.extraActivites)
    ? JSON.parse(localStorage.getItem(defualtKey.extraActivites)).extraActivity
    : defaultValue.extraActivites;

  // languages

  const language = localStorage.getItem(defualtKey.languages)
    ? JSON.parse(localStorage.getItem(defualtKey.languages)).language
    : defaultValue.languages;

  // award

  const award = localStorage.getItem(defualtKey.awards)
    ? JSON.parse(localStorage.getItem(defualtKey.awards)).award
    : defaultValue.awards;
  return (
    <div id="resume4" className="mx-1 mt-2">
      <header className="row justify-content-between position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="1"
        />
        {/* name and role  */}
        <div className="header-left col-6">
          <h2 className="name">{name}</h2>
          <div className="role">{role}</div>
        </div>
        {/* contact : email, phone , linkedin and location */}
        <div className="header-right row col-6">
          <div className="text-end">
            <span className="small">{email}</span>
            <i className="fa-solid fa-envelope ms-2" style={{ color: color }} />
          </div>
          <div className="text-end">
            <span className="small">
              {countryCode} {phoneNumber}
            </span>
            <i class="fa-solid fa-phone ms-2" style={{ color: color }}></i>
          </div>
          <div className="text-end">
            <span className="small">
              {city}, {state}, {country}
            </span>
            <i
              className="fa-solid fa-location-dot ms-2"
              style={{ color: color }}
            />
          </div>
          <div className="text-end">
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className=""
            >
              <small className="small">{linkedin}</small>
            </a>
            <i
              className="fa-solid fa-mobile-screen ms-2"
              style={{ color: color }}
            />
          </div>
        </div>
      </header>
      <hr style={{ border: "2px solid #000000", borderRadius: "15px" }} />
      {/* Summary  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="8"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            SUMMARY
          </h5>
        </div>
        <div className="col-8 small"> {summary}</div>
      </section>
      {/* skills  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="2"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            SKILLS
          </h5>
        </div>
        <div className="col-8 small">
          <div id="skill ">
            {skill.map((skill, id) => {
              return (
                <div className=" d-flex justify-content-between" key={id}>
                  <div className="skill-name">{skill[0]}</div>
                  <SkillCircle circle={skill[1]} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* EXperience  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="3"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            EXPERIENCE
          </h5>
        </div>
        <div className="col-8 small">
          {experience.map((exp, id) => {
            return (
              <div className="experience-box mb-3" key={id}>
                <h5 className="job-company second-heading bold-font">
                  {exp.jobRole}
                </h5>
                <p className="mb-1">{exp.companyName}</p>
                <p className="text-muted mb-1">
                  {exp.jobTiming ? exp.jobTiming.startDate : ""} -
                  {exp.jobTiming ? exp.jobTiming.endDate : ""}
                </p>
                <p className="job-desc">{exp.jobDescription}</p>
              </div>
            );
          })}
        </div>
      </section>
      {/* Education  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="4"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            EDUCATION
          </h5>
        </div>
        <div className="col-8 small">
          {education.map((edu, id) => {
            return (
              <div className="education-box mb-3" key={id}>
                <h5 className="education-college text-dark">
                  {edu.degreeName}
                </h5>
                <div className="education-subject fs-6">
                  {edu.collegeName} - {edu.percentage}
                </div>
                <div className="education-date mb-1">
                  {edu.degreeTiming ? edu.degreeTiming.startDate : ""}
                  <span className="mx-1">-</span>
                  {edu.degreeTiming ? edu.degreeTiming.endDate : ""}
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* Extra Activiry  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="5"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            EXTRA CURRICULAR ACTIVITIES
          </h5>
        </div>
        <div className="col-8 small">
          {extraActivity.map((activity, id) => {
            return (
              <div className="activities-box mb-3" key={id}>
                <h5 className="activity-name">{activity.activityName}</h5>
                <p className="activity-description ">
                  {activity.activityDescription}
                </p>
              </div>
            );
          })}
        </div>
      </section>
      {/* Languages  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="6"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            LANGUAGES
          </h5>
        </div>
        <div className="col-8 small">
          {language.map((language, id) => {
            return (
              <div
                className="language-box d-flex justify-content-between"
                key={id}
              >
                <div className="language-name h6">{language[0]}</div>
                <SkillCircle circle={language[1]} />
              </div>
            );
          })}
        </div>
      </section>
      {/* Awards  */}
      <section className="row mt-3 position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-13px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="7"
        />
        <div className="col-4">
          <h5 className="bold-font" style={{ color: color }}>
            AWARDS
          </h5>
        </div>
        <div className="col-8 small">
          {award.map((award, id) => {
            return (
              <h6 key={id} className="pb-2">
                {award}
              </h6>
            );
          })}
        </div>
      </section>
    </div>
  );
}
