import React, { useState } from "react";

// css
import "../../css/resume/resume2.css";

import EditArrow from "./EditArrow";
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

// image
import resumeLogoImg from "../../img/upload-img.jpeg";
import SkillCircle from "./SkillCircle";
import ResumeInfo from "../../data/resumeInfo";

export default function Resume2({ setEditBox, display, editArrowDisplay }) {
  // color
  // const color = localStorage.getItem(ResumeInfo[0].color[0].name)
  //   ? localStorage.getItem(ResumeInfo[0].color[0].name)
  //   : ResumeInfo[0].color[0].value;

  const mainColor = localStorage.getItem(ResumeInfo[1].color[0].name)
    ? localStorage.getItem(ResumeInfo[1].color[0].name)
    : ResumeInfo[1].color[0].value;
  const leftHeadingColor = localStorage.getItem(ResumeInfo[1].color[1].name)
    ? localStorage.getItem(ResumeInfo[1].color[1].name)
    : ResumeInfo[1].color[1].value;
  const leftBackgroundColor = localStorage.getItem(ResumeInfo[1].color[2].name)
    ? localStorage.getItem(ResumeInfo[1].color[2].name)
    : ResumeInfo[1].color[2].value;
  const rightHeadingColor = localStorage.getItem(ResumeInfo[1].color[3].name)
    ? localStorage.getItem(ResumeInfo[1].color[3].name)
    : ResumeInfo[1].color[3].value;

  const [img, setImg] = useState(resumeLogoImg);

  // Image
  const handleInput = (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  const handleImg = () => {
    document.getElementById("myfile").click();
  };

  // CONTACT INFO

  const email = JSON.parse(localStorage.getItem(defualtKey.email))
    ? JSON.parse(localStorage.getItem(defualtKey.email))
    : defaultValue.email;

  const countryCode = JSON.parse(localStorage.getItem(defualtKey.countryCode))
    ? JSON.parse(localStorage.getItem(defualtKey.countryCode))
    : defaultValue.phone.countryCode;
  const phoneNumber = JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    ? JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    : defaultValue.phone.phoneNumber;

  const city = JSON.parse(localStorage.getItem(defualtKey.city))
    ? JSON.parse(localStorage.getItem(defualtKey.city))
    : defaultValue.address.city;
  const state = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.state;
  const country = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.country;

  const linkedin = JSON.parse(localStorage.getItem(defualtKey.linkedin))
    ? JSON.parse(localStorage.getItem(defualtKey.linkedin))
    : defaultValue.linkedin;

  // skill

  const skill = localStorage.getItem(defualtKey.skills)
    ? JSON.parse(localStorage.getItem(defualtKey.skills)).skill
    : defaultValue.skills;

  // award

  const award = localStorage.getItem(defualtKey.awards)
    ? JSON.parse(localStorage.getItem(defualtKey.awards)).award
    : defaultValue.awards;

  // NAME AND ROLE
  const name = JSON.parse(localStorage.getItem(defualtKey.name))
    ? JSON.parse(localStorage.getItem(defualtKey.name))
    : defaultValue.name;

  const role = JSON.parse(localStorage.getItem(defualtKey.role))
    ? JSON.parse(localStorage.getItem(defualtKey.role))
    : defaultValue.role;

  // SUMMARY

  const summary = JSON.parse(localStorage.getItem(defualtKey.summary))
    ? JSON.parse(localStorage.getItem(defualtKey.summary))
    : defaultValue.summary;

  // experience

  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  // projects

  const projects = localStorage.getItem(defualtKey.projects)
    ? JSON.parse(localStorage.getItem(defualtKey.projects)).projects
    : defaultValue.projects;

  // education

  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  return (
    <div id="resume2" className="row">
      {/* LEFT SIDE  */}

      <aside
        className="col-4 px-0"
        id="left-container"
        style={{ backgroundColor: leftBackgroundColor }}
      >
        {/* photo  */}
        <div className="resume-img mt-3 mx-auto rounded-circle ">
          <div className="resume-img-box">
            <input
              type="file"
              id="myfile"
              name="myfile"
              onChange={(e) => handleInput(e)}
              style={{ display: "hidden" }}
            />
            <img
              src={img}
              alt="Upload an Img"
              onLoad={(e) => URL.revokeObjectURL(e.src)}
              className="resume-img"
              onClick={(e) => {
                handleImg(e);
              }}
            />
          </div>
        </div>
        {/* contact  */}
        <section id="contact-container" className="mt-4 ms-1">
          <h4
            className="second-heading bold-font"
            style={{ color: leftHeadingColor }}
          >
            CONTACT INFO
          </h4>
          <div id="contact">
            <div className="contact-box mb-2">
              <i className="fa-solid fa-location-dot" />
              <small className="ms-1 small-text">
                {city}, {state}, {country}
              </small>
            </div>
            <div className="contact-box mb-2">
              <i className="fa-solid fa-envelope" />
              <small className="ms-2 small-text">{email}</small>
            </div>
            <div className="contact-box mb-2">
              <i className="fa-solid fa-mobile-screen" />
              <small className="ms-2 small-text">
                {countryCode} {phoneNumber}
              </small>
            </div>
            <div className="contact-box contact-box-4 mb-2 d-flex">
              <i className="fa-solid fa-location-dot" />
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="social d-flex justify-content-between ms-2 text-decoration-none"
              >
                <small className="text-white small-text text-decoration-none">
                  {linkedin}
                </small>
              </a>
            </div>
          </div>
        </section>
        {/* skills  */}
        <section id="skills-container" className="mt-5 ms-1 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-23px"
            right="5px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="2"
          />
          <h4
            className="second-heading bold-font"
            style={{ color: leftHeadingColor }}
          >
            SKILLS{" "}
          </h4>
          <div id="skills">
            {skill.map((skill, id) => {
              return (
                <div
                  className="skill-box d-flex flex-lg-column-reverse"
                  key={id}
                >
                  <SkillCircle circle={skill[1]} />
                  <div className="skill-name small ms-1">{skill[0]}</div>
                </div>
              );
            })}
          </div>
        </section>
        {/* awards  */}
        <section id="awards-container" className="mt-5 ms-1 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-13px"
            right="6px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="7"
          />
          <h4
            className="second-heading bold-font"
            style={{ color: leftHeadingColor }}
          >
            AWARDS
          </h4>
          <ul id="awards">
            {award.map((award, id) => {
              return (
                <li key={id} className="pb-2">
                  {award}
                </li>
              );
            })}
          </ul>
        </section>
      </aside>
      {/* RIGHT SIDE  */}
      <aside className="col-8" id="right-container">
        {/* heading  */}
        <div className="heading  mb-5 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-8px"
            right="-11px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="1"
          />
          <div
            className="main-heading text-uppercase"
            style={{ color: mainColor }}
          >
            {name}
          </div>
          <hr style={{ width: "30%", border: "" }} />
          <h5 className="second-heading bold-font">{role}</h5>
        </div>
        {/* summary  */}
        <section id="summary-container" className="mb-4 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-13px"
            right="-9px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="8"
          />
          <h3
            className="second-heading blue bold-font"
            style={{ color: rightHeadingColor }}
          >
            PERSONAL PROFILE
          </h3>
          <div className="small">{summary}</div>
        </section>
        {/* experience */}
        <section id="experience-container" className="mb-4 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-13px"
            right="-9px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="3"
          />
          <h3
            className="second-heading blue bold-font"
            style={{ color: rightHeadingColor }}
          >
            WORK EXPERIENCE
          </h3>
          <div id="experience">
            {experience.map((exp, id) => {
              return (
                <div className="experience-box mb-3" key={id}>
                  <h5 className="job-company second-heading bold-font">
                    {exp.companyName}
                  </h5>
                  <div className="d-flex">
                    <div className="job-role text-muted small">
                      {exp.jobRole}
                    </div>
                    <div className="ms-2 job-date small text-muted">
                      <span>
                        {exp.jobTiming ? exp.jobTiming.startDate : ""}
                      </span>
                      <span className="mx-1">-</span>
                      <span>{exp.jobTiming ? exp.jobTiming.endDate : ""}</span>
                    </div>
                  </div>

                  <p className="job-desc resume1-secondaryHeading">
                    {exp.jobDescription}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        {/* project  */}
        <section id="project-container" className="mb-4 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-13px"
            right="-9px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="9"
          />
          <h3
            className="second-heading blue bold-font"
            style={{ color: rightHeadingColor }}
          >
            PROJECTS
          </h3>
          <div id="project">
            {projects.map((project, id) => {
              return (
                <div className="project-box mb-3" key={id}>
                  <h5 className="second-heading bold-font">
                    {project.projectName}
                  </h5>
                  <li className="project-descrption">
                    {project.projectDescription}
                  </li>
                </div>
              );
            })}
          </div>
        </section>
        {/* education  */}
        <section id="education-container" className="mb-4 position-relative">
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="-13px"
            right="-9px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="4"
          />
          <h3
            className="second-heading blue bold-font"
            style={{ color: rightHeadingColor }}
          >
            EDUCATION
          </h3>
          <div id="education">
            {education.map((edu, id) => {
              return (
                <div className="education-box" key={id}>
                  <h4 className="college second-heading bold-font mt-4">
                    {edu.collegeName}
                  </h4>
                  <p className="text-muted small">
                    {edu.degreeName} |
                    <span>
                      {edu.degreeTiming ? edu.degreeTiming.startDate : ""}
                    </span>
                    <span className="mx-1">-</span>
                    <span>
                      {edu.degreeTiming ? edu.degreeTiming.endDate : ""}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </section>
      </aside>
    </div>
  );
}
