import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
// import ReactToPdf from "react-to-print";
import ReactToPdf from "react-to-pdf";

// Footer and Navbar
import Footer from "../components/homeComponents/Footer";
// import Navbar from "../components/homeComponents/Navbar";

// Resume Information
import ResumeInfo from "../data/resumeInfo";

// css
import "../css/edit.css";

// components
import EmptyBox from "./editComponents/EmptyBox";
import HeaderEdit from "./resumeEditComponents/HeaderEdit";
import SkillsEdit from "./resumeEditComponents/SkillsEdit";
import AwardsEdit from "./resumeEditComponents/AwardsEdit";
import LanguageEdit from "./resumeEditComponents/LanguageEdit";
import ExtraActivityEdit from "./resumeEditComponents/ExtraActivityEdit";
import EducationEdit from "./resumeEditComponents/EducationEdit";
import ExperienceEdit from "./resumeEditComponents/ExperienceEdit";
import SummaryEdit from "./resumeEditComponents/SummaryEdit";
import ProjectEdit from "./resumeEditComponents/ProjectEdit";
import ActivitiesEdit from "./resumeEditComponents/ActivitiesEdit";
import TrainingsEdit from "./resumeEditComponents/TrainingsEdit";
import SkillNameEdit from "./resumeEditComponents/SkillNameEdit";
import CoursesEdit from "./resumeEditComponents/CoursesEdit";
import HobbiesEdit from "./resumeEditComponents/HobbiesEdit";
import SkillInfo from "./resumeEditComponents/SkillInfo";
import SoftwareEdit from "./resumeEditComponents/SoftwareEdit";
import CertificationsEdit from "./resumeEditComponents/CertificationsEdit";
import Navbar from "./editComponents/Navbar";
import Aside from "./editComponents/Aside";
import SelectColor from "./editComponents/SelectColor";
import OffCanvasResume from "./editComponents/OffCanvasResume";

const ref = React.createRef();

export default function Edit() {
  const { resumeId } = useParams();
  const [editBox, setEditBox] = useState("0");

  const [refrence, setRefrence] = useState(ref);

  // display
  const [display, setDisplay] = useState("");

  // Edit Arrow Dispaly
  const [editArrowDisplay, setEditArrowDisplay] = useState("block");

  // state
  const [inc, setInc] = useState("0");

  let defulatComponet;
  switch (editBox) {
    case "1":
      defulatComponet = (
        <HeaderEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "2":
      defulatComponet = (
        <SkillsEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "3":
      defulatComponet = (
        <ExperienceEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "4":
      defulatComponet = (
        <EducationEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "5":
      defulatComponet = (
        <ExtraActivityEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "6":
      defulatComponet = (
        <LanguageEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "7":
      defulatComponet = (
        <AwardsEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "8":
      defulatComponet = (
        <SummaryEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "9":
      defulatComponet = (
        <ProjectEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "10":
      defulatComponet = (
        <ActivitiesEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "11":
      defulatComponet = (
        <TrainingsEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "12":
      defulatComponet = (
        <SkillNameEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "13":
      defulatComponet = (
        <CoursesEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "14":
      defulatComponet = (
        <HobbiesEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "15":
      defulatComponet = (
        <SkillInfo setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "16":
      defulatComponet = (
        <SoftwareEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    case "17":
      defulatComponet = (
        <CertificationsEdit setInc={setInc} inc={inc} setEditBox={setEditBox} />
      );
      break;

    default:
      defulatComponet = <EmptyBox />;
      break;
  }

  // handle Print
  // const componentRef = useRef();
  // const componentRef = React.createRef();

  // reset
  const handleReset = () => {
    localStorage.clear();
    setInc((parseInt(inc) + 1).toString());
  };

  // handle Canvas
  const [canvasResumeDisplay, setCanvasResumeDisplay] = useState("none");
  const handleCanvasResumeDisplay = () => {
    if (canvasResumeDisplay === "none") {
      setCanvasResumeDisplay("block");
    } else {
      setCanvasResumeDisplay("none");
    }
    setEditBox("0");
    setSelectColourDisplay("none");
  };

  //handle Colour Canvas

  const [selectColourDisplay, setSelectColourDisplay] = useState("none");
  const handleSelectColourDisplay = () => {
    if (selectColourDisplay === "none") {
      setSelectColourDisplay("block");
    } else {
      setSelectColourDisplay("none");
    }
    setEditBox("0");
    setCanvasResumeDisplay("none");
  };

  return (
    <>
      <Navbar
        handleReset={handleReset}
        ReactToPdf={ReactToPdf}
        // componentRef={componentRef}
        // ref={ref}
        refrence={refrence}
        setDisplay={setDisplay}
        editArrowDisplay={editArrowDisplay}
        setEditArrowDisplay={setEditArrowDisplay}
      />

      <Aside
        handleCanvasResumeDisplay={handleCanvasResumeDisplay}
        handleSelectColourDisplay={handleSelectColourDisplay}
      />
      {/* <Aside /> */}
      <OffCanvasResume
        canvasResumeDisplay={canvasResumeDisplay}
        handleCanvasResumeDisplay={handleCanvasResumeDisplay}
      />
      {ResumeInfo.map((resume, id) => {
        let url = document.URL.split("/");
        let resumeId = url[url.length - 1];
        if (resumeId === resume.id) {
          return (
            <SelectColor
              handleSelectColourDisplay={handleSelectColourDisplay}
              selectColourDisplay={selectColourDisplay}
              color={resume.color}
              key={id}
              setInc={setInc}
              inc={inc}
              // setColor={setColor}
            />
          );
        }
      })}

      {/* Edit container  */}
      <div className="edit-container pb-5 mx-3">
        {/* ACTION WINDOW AND TEMPALTE VIEW  */}
        <div className="row justify-content-between flex-column-reverse flex-md-row">
          {/* Action window */}
          <div className="col-lg-6">
            <h4 className="my-3">Action window</h4>
            {defulatComponet}
          </div>
          {/* Template View */}
          <div className="col-lg-6 ">
            <h4 className="my-3">Template View</h4>

            <div id="preview" className="rounded">
              <div
                id="resume"
                // targetRef={ref}
                // filename="resume.pdf"
                ref={ref}
                className="py-2 px-3"
              >
                {ResumeInfo.map((resume, id) => {
                  if (resume.id === resumeId) {
                    return resume.component({
                      setEditBox,
                      display,
                      id,
                      refrence,
                      editArrowDisplay,
                    });
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
