import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const CoursesEdit = ({ setEditBox, setInc, inc }) => {
  // Changing Value in LocalStorage
  const courses = localStorage.getItem(defualtKey.courses)
    ? JSON.parse(localStorage.getItem(defualtKey.courses)).courses
    : defaultValue.courses;

  // // Getting Value from Localtorage

  const handleCoursesChange = (e, i) => {
    let newSetCourses = courses;
    newSetCourses[i] = [e.target.value, courses[i][1]];
    localStorage.setItem(
      defualtKey.courses,
      JSON.stringify({ courses: newSetCourses })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  //   Add Skill Name

  const addCourse = () => {
    let newSetCourses = courses;
    newSetCourses.push(defaultValue.courses[0]);
    localStorage.setItem(
      defualtKey.courses,
      JSON.stringify({ courses: newSetCourses })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete Skill Name

  const deleteCourse = (i) => {
    let newSetCourses = courses;
    newSetCourses.splice(i, 1);
    localStorage.setItem(
      defualtKey.courses,
      JSON.stringify({ courses: newSetCourses })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Courses</h3>

      {courses.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value}
              handleChange={(e) => {
                handleCoursesChange(e, index);
              }}
            />

            {courses.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteCourse(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {courses.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addCourse}>
          Add Course
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default CoursesEdit;
