import React from "react";

export default function SkillCircle({ circle }) {
  let arrayChecked = ["", "", "", "", ""];
  for (let i = 0; i < circle; i++) {
    arrayChecked[i] = "checked";
  }
  return (
    <div className="skill-star ">
      {arrayChecked.map((value, id) => {
        if (value === "checked") {
          return <span key={id} className="fa-solid fa-circle checked"></span>;
        } else {
          return <span key={id} className="fa-solid fa-circle"></span>;
        }
      })}
    </div>
  );
}
