import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function ExperienceTemplate({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage newSetExperience
  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  // // Getting Value from Localtorage
  const handleJobRole = (e, i) => {
    let newSetExperience = experience;
    newSetExperience[i] = {
      jobRole: e.target.value,
      companyName: experience[i].companyName,
      jobTiming: {
        startDate: experience[i].jobTiming.startDate,
        endDate: experience[i].jobTiming.endDate,
      },
      jobDescription: experience[i].jobDescription,
    };
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCompanyName = (e, i) => {
    let newSetExperience = experience;
    newSetExperience[i] = {
      jobRole: experience[i].jobRole,
      companyName: e.target.value,
      jobTiming: {
        startDate: experience[i].jobTiming.startDate,
        endDate: experience[i].jobTiming.endDate,
      },
      jobDescription: experience[i].jobDescription,
    };
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleJobStartDate = (e, i) => {
    let newSetExperience = experience;
    newSetExperience[i] = {
      jobRole: experience[i].jobRole,
      companyName: experience[i].companyName,
      jobTiming: {
        startDate: e.target.value.toLocaleString(),
        endDate: experience[i].jobTiming.endDate,
      },
      jobDescription: experience[i].jobDescription,
    };
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleJobEndDate = (e, i) => {
    let newSetExperience = experience;
    newSetExperience[i] = {
      jobRole: experience[i].jobRole,
      companyName: experience[i].companyName,
      jobTiming: {
        startDate: experience[i].jobTiming.startDate,
        endDate: e.target.value.toLocaleString(),
      },
      jobDescription: experience[i].jobDescription,
    };
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleJobDescription = (e, i) => {
    let newSetExperience = experience;
    newSetExperience[i] = {
      jobRole: experience[i].jobRole,
      companyName: experience[i].companyName,
      jobTiming: {
        startDate: experience[i].jobTiming.startDate,
        endDate: experience[i].jobTiming.endDate,
      },
      jobDescription: e.target.value,
    };
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const addExperience = () => {
    let newSetExperience = experience;
    newSetExperience.push(defaultValue.skills[0]);
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteExperience = (i) => {
    let newSetExperience = experience;
    newSetExperience.splice(i, 1);
    localStorage.setItem(
      defualtKey.experience,
      JSON.stringify({ experience: newSetExperience })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Experience</h3>

      {experience.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Job Role"
              type="text"
              defaultValue={value.jobRole}
              handleChange={(e) => {
                handleJobRole(e, index);
              }}
            />
            <Input
              label="Company Name"
              type="text"
              defaultValue={value.companyName}
              handleChange={(e) => {
                handleCompanyName(e, index);
              }}
            />
            <Input
              label="Start Date"
              type="text"
              defaultValue={value.jobTiming.startDate}
              handleChange={(e) => {
                handleJobStartDate(e, index);
              }}
            />
            <Input
              label="End Date"
              type="text"
              defaultValue={value.jobTiming.endDate}
              handleChange={(e) => {
                handleJobEndDate(e, index);
              }}
            />

            <div className="mb-3">
              <label htmlFor="jobdesc" className="form-label">
                Job Description
              </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="4"
                className="form-control"
                defaultValue={value.jobDescription}
                onChange={(e) => {
                  handleJobDescription(e, index);
                }}
              ></textarea>
            </div>

            {experience.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteExperience(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {experience.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addExperience}>
          Add Experience
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
