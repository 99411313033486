import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

const HobbiesEdit = ({ setEditBox, setInc, inc }) => {
  // Changing Value in LocalStorage
  const hobbies = localStorage.getItem(defualtKey.hobbies)
    ? JSON.parse(localStorage.getItem(defualtKey.hobbies)).hobbies
    : defaultValue.hobbies;

  // // Getting Value from Localtorage

  const handlehobbiesChange = (e, i) => {
    let newSetHobbies = hobbies;
    newSetHobbies[i] = [e.target.value, hobbies[i][1]];
    localStorage.setItem(
      defualtKey.hobbies,
      JSON.stringify({ hobbies: newSetHobbies })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  //   Add Skill Name

  const addHobby = () => {
    let newSetHobbies = hobbies;
    newSetHobbies.push(defaultValue.hobbies[0]);
    localStorage.setItem(
      defualtKey.hobbies,
      JSON.stringify({ hobbies: newSetHobbies })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete Skill Name

  const deleteHobby = (i) => {
    let newSetHobbies = hobbies;
    newSetHobbies.splice(i, 1);
    localStorage.setItem(
      defualtKey.hobbies,
      JSON.stringify({ hobbies: newSetHobbies })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Hobbies </h3>

      {hobbies.map((value, index) => {
        return (
          <div key={index}>
            <Input
              type="text"
              defaultValue={value}
              handleChange={(e) => {
                handlehobbiesChange(e, index);
              }}
            />

            {hobbies.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteHobby(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {hobbies.length > 2 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addHobby}>
          Add Hobby
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
};

export default HobbiesEdit;
