import React, { useState } from "react";
import ResumeInfo from "../../data/resumeInfo";
import { Link } from "react-router-dom";

export default function OffCanvasResume({
  canvasResumeDisplay,
  handleCanvasResumeDisplay,
}) {
  let displayArray = [];
  let opacityArray = [];
  for (let i = 0; i < ResumeInfo.length; i++) {
    displayArray.push("none");
    opacityArray.push("1");
  }

  const [display, setDisplay] = useState(displayArray);
  const [opacity, setOpacity] = useState(opacityArray);
  const [inc, setInc] = useState("0");
  return (
    <div
      id="offCanvasResume"
      className="offcanvas-style"
      style={{ display: canvasResumeDisplay }}
    >
      <div className="row">
        <div className="offcanvas-box-1 col-1">
          <aside id="selectTempalte">
            <h6 className="offcanvas-heading text-center mb-3">
              Select Template
            </h6>

            {ResumeInfo.map((resume, id) => {
              return (
                <div
                  className="offcanvas-resume-box col-3"
                  onMouseOver={() => {
                    // console.log(id);
                    display[id] = "block";
                    opacity[id] = "0.4";
                    setDisplay(display);
                    setOpacity(opacity);
                    setInc((parseInt(inc) + 1).toString());
                  }}
                  onMouseOut={() => {
                    display[id] = "none";
                    opacity[id] = "1";
                    setDisplay(display);
                    setOpacity(opacity);
                    setInc((parseInt(inc) - 1).toString());
                  }}
                  key={id}
                  id={id}
                >
                  <img
                    src={resume.image}
                    alt=""
                    className="border border-dark rounded"
                    style={{ opacity: opacity[parseInt(id)] }}
                  />
                  <div
                    className="text-center selectBtnBox offCanvasSelectBtnBox"
                    style={{ display: display[parseInt(id)] }}
                  >
                    <Link
                      to={"/edit/" + resume.id}
                      className="btn btn-primary btn-sm "
                    >
                      Select
                    </Link>
                  </div>
                </div>
              );
            })}
          </aside>
        </div>
        <div
          className="offcanvas-box-2 col-10"
          onClick={handleCanvasResumeDisplay}
        ></div>
      </div>
    </div>
  );
}
