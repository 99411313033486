// Images
import Resume1Img from "../img/resume/1.png";
import Resume2Img from "../img/resume/2.jpg";
import Resume3Img from "../img/resume/3.jpg";
import Resume4Img from "../img/resume/4.jpg";
import Resume5Img from "../img/resume/5.png";

// Components
import Resume1 from "../components/editComponents/Resume1";
import Resume2 from "../components/editComponents/Resume2";
import Resume3 from "../components/editComponents/Resume3";
import Resume4 from "../components/editComponents/Resume4";
import Resume5 from "../components/editComponents/Resume5";

// Information
const ResumeInfo = [
  {
    name: "Resume1",
    image: Resume1Img,
    id: "resume1",
    color: [
      { name: "heading_resume1", heading: "Main Color", value: "#04cef7" },
    ],
    component: ({
      setEditBox,
      componentRef,
      display,
      id,
      color,
      editArrowDisplay,
    }) => {
      return (
        <Resume1
          setEditBox={setEditBox}
          componentRef={componentRef}
          display={display}
          key={id}
          color={color}
          editArrowDisplay={editArrowDisplay}
        />
      );
    },
  },
  {
    name: "Resume2",
    image: Resume2Img,
    id: "resume2",
    color: [
      {
        name: "mainHeading_resume2",
        heading: "Main Heading",
        value: "#2367a9",
      },
      {
        name: "leftHeading_resume2",
        heading: "Left Heading",
        value: "#04cef7",
      },
      {
        name: "leftBackground_resume2",
        heading: "Left Background",
        value: "#243e78",
      },
      {
        name: "rightHeading_resume2",
        heading: "Right Heading",
        value: "#ffffff",
      },
    ],
    component: ({ setEditBox, componentRef, display, editArrowDisplay }) => {
      return (
        <Resume2
          setEditBox={setEditBox}
          display={display}
          editArrowDisplay={editArrowDisplay}
        />
      );
    },
  },
  {
    name: "Resume3",
    image: Resume3Img,
    id: "resume3",
    color: [
      { name: "heading_resume3", heading: "Main Color", value: "#5a20cb" },
    ],
    component: ({ setEditBox, componentRef, display, editArrowDisplay }) => {
      return (
        <Resume3
          setEditBox={setEditBox}
          display={display}
          editArrowDisplay={editArrowDisplay}
        />
      );
    },
  },
  {
    name: "Resume4",
    image: Resume4Img,
    id: "resume4",
    color: [
      { name: "heading_resume4", heading: "Main Color", value: "#04cef7" },
    ],
    component: ({ setEditBox, componentRef, display, editArrowDisplay }) => {
      return (
        <Resume4
          setEditBox={setEditBox}
          display={display}
          editArrowDisplay={editArrowDisplay}
        />
      );
    },
  },
  {
    name: "Resume5",
    image: Resume5Img,
    id: "resume5",
    color: [
      { name: "heading_resume5", heading: "Main Color", value: "#000000" },
    ],
    component: ({ setEditBox, componentRef, display, editArrowDisplay }) => {
      return (
        <Resume5
          setEditBox={setEditBox}
          display={display}
          editArrowDisplay={editArrowDisplay}
        />
      );
    },
  },
];

export default ResumeInfo;
