import React, { useState } from "react";
import ResumeInfo from "../../data/resumeInfo";
import { Link } from "react-router-dom";

export default function Resumes() {
  let displayArray = [];
  let opacityArray = [];
  for (let i = 0; i < ResumeInfo.length; i++) {
    displayArray.push("none");
    opacityArray.push("1");
  }
  const [display, setDisplay] = useState(displayArray);
  const [opacity, setOpacity] = useState(opacityArray);
  const [inc, setInc] = useState("0");

  return (
    <div className="resume-container container my-5">
      <div className="row justify-content-center">
        {ResumeInfo.map((resume, id) => {
          return (
            <div
              className="resume-box col-3"
              onMouseOver={() => {
                display[id] = "block";
                opacity[id] = "0.4";
                setDisplay(display);
                setOpacity(opacity);
                setInc((parseInt(inc) + 1).toString());
              }}
              onMouseOut={() => {
                display[id] = "none";
                opacity[id] = "1";
                setDisplay(display);
                setOpacity(opacity);
                setInc((parseInt(inc) - 1).toString());
              }}
              key={id}
              id={id}
            >
              <img
                src={resume.image}
                alt=""
                className="border border-dark rounded"
                style={{ opacity: opacity[parseInt(id)] }}
              />
              <div
                className="text-center selectBtnBox"
                style={{ display: display[parseInt(id)] }}
              >
                <Link
                  to={"edit/" + resume.id}
                  className="btn btn-primary btn-sm selectBtn"
                >
                  Select
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
