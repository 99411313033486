const API = process.env.REACT_APP_API;

module.exports = {
  // Auth
  register: `${API}/api/auth/register`,
  login: `${API}/api/auth/login`,
  getuser: `${API}/api/auth/getuser`,
  getAllUser: `${API}/getAllUser`,

  // Resume
  fetchResume: `${API}/resume/fetchResume`,
  insertResume: `${API}/api/resume/insertResume`,
  updateResume: `${API}/api/resume/updateResume`,
};
