import { useState, useEffect } from "react";
import ResumeContext from "./ResumeContext";
import { getuser } from "../data/config";
import axios from "axios";
import { fetchResume } from "../data/config";

// toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResumeState = (props) => {
  const [user, setUser] = useState("");
  const [resumeData, setResumeData] = useState([]);

  //   AUTH STATE
  let authToken = localStorage.getItem("token");

  const [token] = useState(authToken);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //   Verify Token and show user loggedIn or not

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, []);

  const verifyToken = async () => {
    try {
      const result = await axios.post(
        getuser,
        {},
        {
          headers: {
            authToken: token,
          },
        }
      );

      if (result.status === 200) {
        setIsLoggedIn(true);
        setUser(result.data._id);

        // fetch resume data
        axios
          .get(fetchResume, {
            user: user,
          })
          .then((result) => {
            setResumeData(result.data.resume);
            resumeData.forEach((data) => {
              localStorage.setItem(data.key, JSON.stringify(data.value));
            });
          });
      }
    } catch (error) {
      // console.log(error);
      setResumeData(null);
    }
  };

  // REACT TOASTIFY

  const success = (e) =>
    toast.success(e, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const failure = (e) =>
    toast.error(e, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  return (
    <ResumeContext.Provider
      value={{
        token,
        isLoggedIn,
        setIsLoggedIn,
        user,
        success,
        failure,
        verifyToken,
      }}
    >
      {props.children}
    </ResumeContext.Provider>
  );
};

export default ResumeState;
