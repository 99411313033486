import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function ExtraActivityEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage newSetExtraActivity
  const extraActivity = localStorage.getItem(defualtKey.extraActivites)
    ? JSON.parse(localStorage.getItem(defualtKey.extraActivites)).extraActivity
    : defaultValue.extraActivites;

  // // Getting Value from Localtorage

  const handleExtraActivityNameChange = (e, i) => {
    let newSetExtraActivity = extraActivity;
    newSetExtraActivity[i] = {
      activityName: e.target.value,
      activityDescription: extraActivity[i].activityDescription,
    };
    localStorage.setItem(
      defualtKey.extraActivites,
      JSON.stringify({ extraActivity: newSetExtraActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleExtraActivityDescriptionChange = (e, i) => {
    let newSetExtraActivity = extraActivity;
    newSetExtraActivity[i] = {
      activityName: extraActivity[i].activityName,
      activityDescription: e.target.value,
    };
    localStorage.setItem(
      defualtKey.extraActivites,
      JSON.stringify({ extraActivity: newSetExtraActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const deleteExtraActivity = (i) => {
    let newSetExtraActivity = extraActivity;
    newSetExtraActivity.splice(i, 1);
    localStorage.setItem(
      defualtKey.extraActivites,
      JSON.stringify({ extraActivity: newSetExtraActivity })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Extra Activites</h3>

      {extraActivity.map((value, index) => {
        return (
          <div key={index}>
            <Input
              label="Extra Activity Name"
              type="text"
              defaultValue={value.activityName}
              handleChange={(e) => {
                handleExtraActivityNameChange(e, index);
              }}
            />
            <div className="mb-3">
              <label htmlFor="actv-desc" className="form-label">
                Extra Activity Descrption
              </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="4"
                className="form-control"
                defaultValue={value.activityDescription}
                onChange={(e) => {
                  handleExtraActivityDescriptionChange(e, index);
                }}
              ></textarea>
            </div>

            {extraActivity.length >= 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteExtraActivity(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
