import React from "react";
import Navbar from "../components/homeComponents/Navbar";
import Header from "../components/homeComponents/Header";
import Hero from "../components/homeComponents/Hero";
import Resumes from "../components/homeComponents/Resumes";
import Footer from "../components/homeComponents/Footer";

// css
import "../css/utility.css";
import "../css/home.css";

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <Hero />
      <Resumes />
      <Footer />
    </>
  );
}
