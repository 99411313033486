import axios from "axios";
import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import ResumeContext from "../../context/ResumeContext";
import { insertResume, fetchResume, updateResume } from "../../data/config";
import LoginModal from "../homeComponents/login/LoginModal";
import Heart from "./heart.svg";

export default function Navbar({
  handleReset,
  refrence,
  setDisplay,
  ReactToPdf,
  editArrowDisplay,
  setEditArrowDisplay,
}) {
  const handleMouseEnter = () => {
    setEditArrowDisplay("none");
  };
  const handleMouseLeave = () => {
    setEditArrowDisplay("block");
  };

  const options = {
    unit: "mm",
    format: [210, 297],
  };

  const context = useContext(ResumeContext);
  const { isLoggedIn, user, success, failure } = context;
  console.log(user);

  const saveResume = () => {
    let localStorageDatas = [];
    if (!isLoggedIn) {
      document.getElementById("loginModalOpen").click();
    } else {
      for (let i = 0; i < localStorage.length; i++) {
        let newObject = {
          key: "",
          value: "",
        };

        newObject.key = localStorage.key(i);
        if (newObject.key !== "token") {
          let myStringedValue = localStorage.getItem(localStorage.key(i));
          if (
            myStringedValue[0] === `"` &&
            myStringedValue[myStringedValue.length - 1] === `"`
          ) {
            let myParsedValue = myStringedValue.split(`"`);
            let mySecondValue = myParsedValue[1];
            newObject.value = mySecondValue;
          } else {
            newObject.value = myStringedValue;
          }

          //

          localStorageDatas.push(newObject);
        }
      }

      // Check the Resume is already exist
      axios
        .get(fetchResume, {
          user: user,
        })
        .then((result) => {
          if (!result.data === true) {
            // Insert Resume
            axios
              .post(insertResume, {
                user: user,
                resumeData: localStorageDatas,
              })
              .then((result) => {
                success("Data is Stored Successfully!");
              })
              .catch((e) => {
                // console.log(e);
                failure("Something Went Wrong!");
              });
          } else {
            // Update Resume
            axios
              .post(updateResume, {
                user: user,
                resumeData: localStorageDatas,
              })
              .then((result) => {
                success("Data is Updated Successfully!");
              })
              .catch((e) => {
                // console.log(e);
                failure("Something Went Wrong!");
              });
          }
        })
        .catch((e) => {
          // console.log(e);
          failure("Something Went Wrong!");
        });
    }
  };
  return (
    <>
      <div>
        {/* navbar  */}
        <nav className="fixed-top edit-navabr border-bottom">
          <div className="container-fluid d-flex flex-column flex-sm-row justify-content-between align-items-center">
            <Link to="/" className="text-dark text-decoration-none fs-3">
              Resume Builder
            </Link>
            <div className="edit-navbar-buttons d-flex  gap-3">
              <button
                className="btn btn-sm btn-outline-dark"
                onClick={handleReset}
              >
                Reset
              </button>
              <ReactToPdf
                targetRef={refrence}
                filename="resume.pdf"
                options={options}
                scale="0.9"
              >
                {({ toPdf }) => (
                  <button
                    onClick={toPdf}
                    className="btn btn-sm btn-dark "
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Download
                  </button>
                )}
              </ReactToPdf>
              <button
                className="btn btn-sm btn-outline-dark"
                style={{ display: "none" }}
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
                id="loginModalOpen"
              >
                Login
              </button>
              <button
                className="btn btn-sm btn-success d-flex align-items-center "
                onClick={saveResume}
              >
                <img
                  src={Heart}
                  alt="save"
                  width={16}
                  style={{ filter: "invert(1)" }}
                />
                <span className="ms-1"> Save </span>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <LoginModal />
    </>
  );
}
