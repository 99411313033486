import React, { useState } from "react";
import "./login.css";
import { register, login } from "../../../data/config";
import axios from "axios";

import { useContext } from "react";
import ResumeContext from "../../../context/ResumeContext";

// TOASTIFY
import { ToastContainer } from "react-toastify";

export default function LoginModal() {
  const context = useContext(ResumeContext);
  const { setIsLoggedIn, success, failure, verifyToken } = context;
  const [auth, setAuth] = useState("login");
  const [credentials, setCredentials] = useState({
    userName: "",
    email: "",
    password: "",
  });

  // Register or Login
  const handleLogin = () => {
    if (auth === "login") {
      setAuth("register");
    } else {
      setAuth("login");
    }
  };

  //Login
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(login, {
        email: credentials.email,
        password: credentials.password,
      });
      success("Login Successfully!");
      setIsLoggedIn(true);
      localStorage.setItem("token", result.data.authToken);
      document.getElementById("loginModal").click();
      verifyToken();
    } catch (error) {
      failure(error.response.data);
    }
  };

  // Register
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(register, {
        name: credentials.userName,
        email: credentials.email,
        password: credentials.password,
      });

      success("User created Successfully!");
      setIsLoggedIn(true);
      localStorage.setItem("token", result.data.authToken);
      document.getElementById("loginModal").click();
      verifyToken();
    } catch (error) {
      failure(error.response.data);
    }
  };

  // onchange
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex={-1}
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div className="modal-content">
            <div className="text-center mt-3 mb-2">
              <h3 className="">{auth === "login" ? "Login" : "Register"}</h3>
            </div>
            <div className="modal-body mx-3 pb-0">
              <form>
                {/* name  */}
                {auth === "register" && (
                  <div className="mb-3">
                    <label htmlFor="loginInputName" className="form-label">
                      Name:
                    </label>
                    <input
                      type="text"
                      name="userName"
                      className="form-control login-input"
                      id="userName"
                      aria-describedby="nameHelp"
                      value={credentials.userName}
                      onChange={onChange}
                    />
                  </div>
                )}
                {/* email  */}
                <div className="mb-3">
                  <label htmlFor="loginInputEmail1" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control login-input"
                    id="email"
                    aria-describedby="emailHelp"
                    name="email"
                    value={credentials.email}
                    onChange={onChange}
                  />
                </div>
                {/* password  */}
                <div className="mb-3">
                  <label htmlFor="loginInputPassword1" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control login-input"
                    id="Password"
                    name="password"
                    value={credentials.password}
                    onChange={onChange}
                  />
                </div>

                <div className="row mx-0">
                  {/* If Login : Login Button  */}
                  {auth === "login" && (
                    <button
                      type="submit"
                      className="btn btn-primary login-submit "
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                  {/* If Register : Register Button  */}
                  {auth === "register" && (
                    <button
                      type="submit"
                      className="btn btn-primary login-submit "
                      onClick={handleRegister}
                    >
                      Register
                    </button>
                  )}
                </div>
              </form>

              {/* REgister  */}
              <div className="text-center my-2">
                <p>or</p>
                <p>
                  {auth === "login"
                    ? "Don't have an account? "
                    : "Already hava an account?"}
                </p>
                <h6 className="btn-link" onClick={handleLogin} role="button">
                  {auth === "login" ? "Sign Up" : "Sign In"}
                </h6>
              </div>
            </div>
            <div className="modal-footer border-top-0">
              <div className="row"></div>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
