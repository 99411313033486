import React from "react";

// css
import "../../css/resume/resume5.css";

import EditArrow from "./EditArrow";
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";
import SkillCircle from "./SkillCircle";
import ResumeInfo from "../../data/resumeInfo";

export default function Resume5({ display, setEditBox, editArrowDisplay }) {
  // Color
  const color = localStorage.getItem(ResumeInfo[4].color[0].name)
    ? localStorage.getItem(ResumeInfo[4].color[0].name)
    : ResumeInfo[4].color[0].value;

  // Header: name, role and contact

  const name = JSON.parse(localStorage.getItem(defualtKey.name))
    ? JSON.parse(localStorage.getItem(defualtKey.name))
    : defaultValue.name;

  const role = JSON.parse(localStorage.getItem(defualtKey.role))
    ? JSON.parse(localStorage.getItem(defualtKey.role))
    : defaultValue.role;

  const email = JSON.parse(localStorage.getItem(defualtKey.email))
    ? JSON.parse(localStorage.getItem(defualtKey.email))
    : defaultValue.email;

  const countryCode = JSON.parse(localStorage.getItem(defualtKey.countryCode))
    ? JSON.parse(localStorage.getItem(defualtKey.countryCode))
    : defaultValue.phone.countryCode;
  const phoneNumber = JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    ? JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    : defaultValue.phone.phoneNumber;

  const city = JSON.parse(localStorage.getItem(defualtKey.city))
    ? JSON.parse(localStorage.getItem(defualtKey.city))
    : defaultValue.address.city;
  const state = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.state;
  const country = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.country;

  const linkedin = JSON.parse(localStorage.getItem(defualtKey.linkedin))
    ? JSON.parse(localStorage.getItem(defualtKey.linkedin))
    : defaultValue.linkedin;

  //summary
  const summary = JSON.parse(localStorage.getItem(defualtKey.summary))
    ? JSON.parse(localStorage.getItem(defualtKey.summary))
    : defaultValue.summary;

  //experience

  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  //education
  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  //skillInfo
  const skillInfo = localStorage.getItem(defualtKey.skillInfo)
    ? JSON.parse(localStorage.getItem(defualtKey.skillInfo)).skillInfo
    : defaultValue.skillInfo;

  //softwares
  const software = localStorage.getItem(defualtKey.software)
    ? JSON.parse(localStorage.getItem(defualtKey.software)).software
    : defaultValue.software;

  //certificates
  const certifications = localStorage.getItem(defualtKey.certifications)
    ? JSON.parse(localStorage.getItem(defualtKey.certifications)).certifications
    : defaultValue.certifications;

  return (
    <div id="resume5">
      <header className="position-relative">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="0px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="1"
        />
        {/* name  */}
        <div className="names">
          <h1 className="lobster-font">{name}</h1>
          <h5 className="bold-font" style={{ color: color }}>
            {role}
          </h5>
        </div>
        {/* contact : Address, mobile , email and linkedIn  */}
        <div className="contact row">
          <div className="col-5 mb-2">
            <span className="bold-font">Phone:</span>
            <span className="ms-1">
              {countryCode} {phoneNumber}
            </span>
          </div>
          <div className="col-5 mb-2">
            <span className="bold-font">Email:</span>
            <span className="ms-1">{email}</span>
          </div>
          <div className="col-5 mb-2">
            <span className="bold-font">LinkedIn:</span>
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <small className="small ms-1">{linkedin}</small>
            </a>
          </div>
          <div className="col-6 mb-2">
            <span className="bold-font">Address:</span>
            <span className="ms-1">
              {city}, {state}, {country}
            </span>
          </div>
        </div>
        {/* summary  */}
        <div className="summary mt-1 small">{summary}</div>
      </header>
      {/* experience */}
      <section className="mt-2  position-relative" id="experience-container">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="20px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="3"
        />
        <h2 className="lobster-font" style={{ color: color }}>
          Experience
        </h2>
        <div id="experience">
          {experience.map((exp, id) => {
            return (
              <div className="experience-box row" key={id}>
                <div className="timing h6 mt-1 col-3 col-md-3">
                  {exp.jobTiming ? exp.jobTiming.startDate : ""}
                  <span className="mx-1">-</span>
                  {exp.jobTiming ? exp.jobTiming.endDate : ""}
                </div>
                <div className="ms-3 col-8 col-md-8">
                  <h4 className="bold-font">{exp.jobRole}</h4>
                  <p className="mb-1 fst-italic">{exp.companyName}</p>
                  <p>{exp.jobDescription}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* education  */}
      <section className="mt-2  position-relative" id="education-container">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="20px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="4"
        />
        <h2 className="lobster-font" style={{ color: color }}>
          Education
        </h2>
        <div id="education">
          {education.map((edu, id) => {
            return (
              <div className="education-box row" key={id}>
                <div className="timing h6 mt-1 col-3 col-md-3">
                  {edu.degreeTiming.startDate ? edu.degreeTiming.startDate : ""}
                  <span className="mx-1">-</span>
                  {edu.degreeTiming.endDate ? edu.degreeTiming.endDate : ""}
                </div>
                <div className="ms-3 col-8 col-md-8">
                  <h4 className="bold-font">{edu.degreeName}</h4>
                  <p className="mb-1">{edu.collegeName}</p>
                  <p>{edu.percentage}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* skills  */}
      <section className="mt-2  position-relative" id="skills-container">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="20px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="15"
        />
        <h2 className="lobster-font" style={{ color: color }}>
          Skills
        </h2>
        <div id="skills" className="row">
          {skillInfo.map((skill, id) => {
            return (
              <li key={id} className="mb-2">
                <strong> {skill.skillName} </strong>
                <span className="mx-1">-</span>
                <span>{skill.skillDescription}</span>
                <small>
                  history of successful innovations leading to cost savings.
                </small>
              </li>
            );
          })}
        </div>
      </section>
      {/* software  */}
      <section className="mt-2  position-relative" id="software">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="20px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="16"
        />
        <h2 className="lobster-font" style={{ color: color }}>
          Software
        </h2>
        <div id="software">
          {software.map((software, id) => {
            return (
              <div key={id}>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">{software[0]}</p>
                  <SkillCircle circle={software[1]} />
                </div>
                <div className="d-flex mb-2 justify-content-end">
                  {software[1] === 5 && "Excellent"}
                  {software[1] === 4 && "Very Good"}
                  {software[1] === 3 && "Average"}
                  {software[1] === 2 && "Some Base"}
                  {software[1] === 1 && "Base"}
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* certifications */}
      <section className="mt-2  position-relative" id="certifications">
        <EditArrow
          editArrowDisplay={editArrowDisplay}
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="20px"
          right="-9px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="17"
        />
        <h2 className="lobster-font" style={{ color: color }}>
          Certifications
        </h2>
        <div id="certificate">
          {certifications.map((certificate, id) => {
            return (
              <div className="certificate-box row mb-2" key={id}>
                <div className="timing h6 mt-1 col-3 col-md-3">
                  {certificate.period}
                </div>
                <div className="ms-3 col-8 col-md-8">
                  {certificate.certificate}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
