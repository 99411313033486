import React from "react";

export default function SelectColor({
  selectColourDisplay,
  handleSelectColourDisplay,
  color,
  setInc,
  inc,
}) {
  const handleColorChange = (e, name) => {
    localStorage.setItem(name, e.target.value);
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div
      id="selectcolour"
      className="offcanvas-style text-center"
      style={{ display: selectColourDisplay }}
      // className="mt-5"
    >
      <h5 className="py-4">
        <span className="text-center"> Select Colour </span>
      </h5>
      <div className="color-select mb-5">
        {color.map((color, id) => {
          return (
            <div className="mx-3 mb-3" key={id}>
              <label htmlFor="main-color" className="form-label">
                {/* {color[0].heading} */}
                {color.heading}
              </label>
              <input
                type="color"
                className="form-control"
                // defaultValue={color[0].value}
                defaultValue={color.value}
                onChange={(e) => {
                  // handleColorChange(e, color[0].name);
                  handleColorChange(e, color.name);
                }}
              />
            </div>
          );
        })}
      </div>
      <button
        className="btn btn-sm btn-danger"
        onClick={handleSelectColourDisplay}
      >
        Close
      </button>
    </div>
  );
}
