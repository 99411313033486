module.exports = {
  name: "Adam Brandon",
  role: "Project Manager",
  email: "adambrandon@gmail.com",
  phone: {
    countryCode: "+91",
    phoneNumber: "1234567890",
  },
  address: {
    city: "Ahmedabad",
    state: "Gujarat",
    country: "India",
  },
  linkedin: "http://linkedin.com/",
  summary:
    "I manage the software development projects and facilitate sprint releases. I recommend upcoming software trends and strategies to be used in the projects. I manage the software development projects and facilitate sprint releases. I recommend upcoming software trends and strategies to be used in the projects",
  skills: [
    ["Excel", 5],
    ["Data Analyst", 3],
    ["Machine Learning", 4],
  ],
  experience: [
    {
      jobRole: "Data Analyst",
      companyName: "MS Technologies Private Limited",
      jobTiming: {
        startDate: "Oct 2016",
        endDate: "Apr 2017",
      },
      jobDescription: "Visualizing Data",
    },
    {
      jobRole: "MS Excel",
      companyName: "MS Technologies Private Limited",
      jobTiming: {
        startDate: "Oct 2016",
        endDate: "Apr 2017",
      },
      jobDescription: "Managing all the data with MS Excel",
    },
  ],
  education: [
    {
      degreeName: "Diploma in Computer Science",
      collegeName: "National Diploma Center",
      degreeTiming: {
        startDate: "Oct 2021",
        endDate: "Apr 2019",
      },
      percentage: "75%",
    },
    {
      degreeName: "12th",
      collegeName: "Sant. Marry School",
      degreeTiming: {
        startDate: "Oct 2017",
        endDate: "Apr 2019",
      },
      percentage: "80%",
    },
  ],
  extraActivites: [
    {
      activityName: "Gold Medal in National Badminton Championship",
      activityDescription:
        "I participated in national badminton chamption which I achieved gold medal.",
    },
  ],
  activities: [
    "Gold Medal in National Badminton Championship",
    "Silver in Mens Badminton Championship",
  ],
  languages: [
    ["Hindi", 4],
    ["English", 5],
  ],
  awards: ["National Sports Leaders Award", "Silver in Mens Kabaddi League"],
  projects: [
    {
      projectName: "Campus Event",
      projectDescription:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis magni reiciendis tempore inventore esse ad sed, quo deleniti minima sint est provident exercitationem ut suscipit commodi nihil voluptatem",
    },
  ],
  trainings: [
    {
      trainingName: "Summer Internship",
      trainingCategory: "Junior Data Analyist",
      trainingInstitute: "SH Technology",
      trainingPeriod: "April 2019",
    },
    {
      trainingName: "Winter Internship",
      trainingCategory: "Junior Data Analyist",
      trainingInstitute: "SH Technology",
      trainingPeriod: "December 2018",
    },
  ],
  skillName: ["MS Excel", "Python", "SQL", "Maths"],
  courses: ["Intoduction to MySQL", "Basic Data Science"],
  hobbies: ["Cricket", "Badminton", "Travelling"],
  skillInfo: [
    {
      skillName: "Data Analyist",
      skillDescription: "expertise in Market research and logistics",
    },
    {
      skillName: "Statistics",
      skillDescription: "Mathematics and statistics",
    },
    {
      skillName: "MySQL",
      skillDescription: "Managing data on SQL database",
    },
    {
      skillName: "MS Excel",
      skillDescription: "Arranging data into Pivort Table",
    },
  ],
  software: [
    ["MS Office", 5],
    ["Tally ERP", 4],
  ],
  certifications: [
    {
      period: "2018-04",
      certificate: "The 2022 Project Maangement Bootcamp",
    },
    {
      period: "2018-03",
      certificate: "MS Excel Advance",
    },
  ],
};
