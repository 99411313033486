import React from "react";
import SkillStar from "./SkillStar";
import EditArrow from "./EditArrow";
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";
import ResumeInfo from "../../data/resumeInfo";

const Resume1 = ({ setEditBox, display, editArrowDisplay }) => {
  // color
  const color = localStorage.getItem(ResumeInfo[0].color[0].name)
    ? localStorage.getItem(ResumeInfo[0].color[0].name)
    : ResumeInfo[0].color[0].value;

  // header
  const name = JSON.parse(localStorage.getItem(defualtKey.name))
    ? JSON.parse(localStorage.getItem(defualtKey.name))
    : defaultValue.name;

  const role = JSON.parse(localStorage.getItem(defualtKey.role))
    ? JSON.parse(localStorage.getItem(defualtKey.role))
    : defaultValue.role;

  const email = JSON.parse(localStorage.getItem(defualtKey.email))
    ? JSON.parse(localStorage.getItem(defualtKey.email))
    : defaultValue.email;

  const countryCode = JSON.parse(localStorage.getItem(defualtKey.countryCode))
    ? JSON.parse(localStorage.getItem(defualtKey.countryCode))
    : defaultValue.phone.countryCode;
  const phoneNumber = JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    ? JSON.parse(localStorage.getItem(defualtKey.phoneNumber))
    : defaultValue.phone.phoneNumber;

  const city = JSON.parse(localStorage.getItem(defualtKey.city))
    ? JSON.parse(localStorage.getItem(defualtKey.city))
    : defaultValue.address.city;
  const state = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.state;
  const country = JSON.parse(localStorage.getItem(defualtKey.state))
    ? JSON.parse(localStorage.getItem(defualtKey.state))
    : defaultValue.address.country;

  const linkedin = JSON.parse(localStorage.getItem(defualtKey.linkedin))
    ? JSON.parse(localStorage.getItem(defualtKey.linkedin))
    : defaultValue.linkedin;

  // skill

  const skill = localStorage.getItem(defualtKey.skills)
    ? JSON.parse(localStorage.getItem(defualtKey.skills)).skill
    : defaultValue.skills;

  // experience

  const experience = localStorage.getItem(defualtKey.experience)
    ? JSON.parse(localStorage.getItem(defualtKey.experience)).experience
    : defaultValue.experience;

  // education

  const education = localStorage.getItem(defualtKey.education)
    ? JSON.parse(localStorage.getItem(defualtKey.education)).education
    : defaultValue.education;

  // extraActivity

  const extraActivity = localStorage.getItem(defualtKey.extraActivites)
    ? JSON.parse(localStorage.getItem(defualtKey.extraActivites)).extraActivity
    : defaultValue.extraActivites;

  // languages

  const language = localStorage.getItem(defualtKey.languages)
    ? JSON.parse(localStorage.getItem(defualtKey.languages)).language
    : defaultValue.languages;

  // award

  const award = localStorage.getItem(defualtKey.awards)
    ? JSON.parse(localStorage.getItem(defualtKey.awards)).award
    : defaultValue.awards;

  return (
    <div id="resume1">
      <header className="d-flex justify-content-between position-relative pt-2 resume1-header">
        <EditArrow
          height="20px"
          width="20px"
          arrowWidth="12px"
          top="-8px"
          right="-11px"
          display={display}
          className="header-arrow"
          setEditBox={setEditBox}
          value="1"
          editArrowDisplay={editArrowDisplay}
        />
        {/* name and role  */}
        <div className="header-left">
          <h4 className="name title">{name}</h4>
          <p>{role}</p>
        </div>
        {/* social: email, mobile, address, linkedin  */}
        <div className="header-right">
          <div
            className="social d-flex align-items-center justify-content-between"
            id="email"
          >
            <small className="me-2">{email}</small>
            <i className="fa-solid fa-envelope" />
          </div>
          <div
            className="social d-flex align-items-center justify-content-between"
            id="mobile"
          >
            <small className="me-2">
              <span>{countryCode}</span>
              <span>{phoneNumber}</span>
            </small>
            <i className="fa-solid fa-mobile-screen" />
          </div>
          <div
            className="social d-flex align-items-center justify-content-between"
            id="address"
          >
            <small>
              <span>{city}</span>
              <span className="mx-1">,</span>
              <span>{state}</span>
              <span className="mx-1">,</span>
              <span>{country}</span>
            </small>
            <i className="fa-solid fa-location-dot" />
          </div>
          <div
            className="social d-flex align-items-center justify-content-between"
            id="address"
          >
            <a href={linkedin} className="me-1">
              <small>{linkedin}</small>
            </a>
            <i className="fa-brands fa-linkedin-in" />
          </div>
        </div>
      </header>
      <hr style={{ border: "2px solid black" }} />
      <div>
        <div className="ps-1"></div>
        {/* skills  */}
        <div
          id="skill-container"
          className="row justify-content-between align-items-baseline position-relative pt-3"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="20px"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="2"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">SKILLS</h4>
          </div>
          <div id="skills" className="col-8">
            {skill.map((skill, id) => {
              return (
                <div
                  className="skill-box d-flex justify-content-between"
                  key={id}
                >
                  <div className="skill-name small">{skill[0]}</div>

                  <SkillStar star={skill[1]} />
                </div>
              );
            })}
          </div>
        </div>
        {/* experience  */}
        <section
          id="experience-container"
          className="row justify-content-between align-items-baseline position-relative"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="1vw"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="3"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">EXPERIENCE</h4>
          </div>
          <div id="experience" className="col-8">
            {experience.map((exp, id) => {
              return (
                <div className="experience-box mb-3" key={id}>
                  <h5 className="job-role">{exp.jobRole} </h5>
                  <div className="job-company">{exp.companyName}</div>
                  <div className="job-date small text-muted">
                    <span>{exp.jobTiming ? exp.jobTiming.startDate : ""}</span>
                    <span className="mx-1">to</span>
                    <span>{exp.jobTiming ? exp.jobTiming.endDate : ""}</span>
                  </div>
                  <p className="job-desc resume1-secondaryHeading">
                    {exp.jobDescription}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        {/* education  */}
        <section
          id="education-container"
          className="row justify-content-between align-items-baseline position-relative"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="1vw"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="4"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">EDUCATION</h4>
          </div>
          <div id="education" className="col-8">
            {education.map((edu, id) => {
              return (
                <div className="education-box mb-3" key={id}>
                  <h5 className="education-subject">{edu.degreeName}</h5>
                  <p className="education-college mb-1">{edu.collegeName}</p>
                  <span className="date small">
                    {edu.degreeTiming.startDate +
                      " to " +
                      edu.degreeTiming.endDate}
                  </span>
                </div>
              );
            })}
          </div>
        </section>
        {/* extra activities  */}
        <section
          id="ativities-container"
          className="row justify-content-between align-items-baseline position-relative"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="1vw"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="5"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">EXTRA ACTIVITIES</h4>
          </div>
          <div id="activites" className="col-8">
            {extraActivity.map((activity, id) => {
              return (
                <div className="activities-box mb-3" key={id}>
                  <h5 className="activity-name">{activity.activityName}</h5>
                  <p className="activity-description small">
                    {activity.activityDescription}
                  </p>
                </div>
              );
            })}
          </div>
        </section>
        {/* languages  */}
        <section
          id="languages-container"
          className="row justify-content-between align-items-baseline position-relative"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="1vw"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="6"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">LANGUAGES</h4>
          </div>
          <div className="languages col-8">
            {language.map((language, id) => {
              return (
                <div
                  className="language-box d-flex justify-content-between"
                  key={id}
                >
                  <div className="language-name h6">{language[0]}</div>
                  <SkillStar star={language[1]} />
                </div>
              );
            })}
          </div>
        </section>
        {/* Award  */}
        <section
          id="award-section"
          className="row justify-content-between align-items-baseline position-relative"
        >
          <EditArrow
            editArrowDisplay={editArrowDisplay}
            height="20px"
            width="1vw"
            arrowWidth="12px"
            top="0px"
            right="0px"
            display={display}
            className="header-arrow"
            setEditBox={setEditBox}
            value="7"
          />
          <div className="col-2" style={{ color: color }}>
            <h4 className="second-heading">AWARDS</h4>
          </div>
          <div className="award col-8">
            {award.map((award, id) => {
              return (
                <h6 key={id} className="pb-2">
                  {award}
                </h6>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Resume1;
