import React from "react";
import Input from "./Input";
import Discard from "./Discard";

// default
import defualtKey from "../../data/defualt-key";
import defaultValue from "../../data/default-value";

export default function SoftwareEdit({ setInc, inc, setEditBox }) {
  // Changing Value in LocalStorage
  const software = localStorage.getItem(defualtKey.software)
    ? JSON.parse(localStorage.getItem(defualtKey.software)).software
    : defaultValue.software;

  // // Getting Value from Localtorage

  const handleSoftwareChange = (e, i) => {
    let newSetSoftware = software;
    newSetSoftware[i] = [e.target.value, software[i][1]];
    localStorage.setItem(
      defualtKey.software,
      JSON.stringify({ software: newSetSoftware })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  const handleCircleChange = (e, i) => {
    let newSetSoftware = software;
    newSetSoftware[i] = [software[i][0], e.target.value];
    localStorage.setItem(
      defualtKey.software,
      JSON.stringify({ software: newSetSoftware })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Add software

  const addNewSoftware = () => {
    let newSetSoftware = software;
    newSetSoftware.push(defaultValue.software[0]);
    localStorage.setItem(
      defualtKey.software,
      JSON.stringify({ software: newSetSoftware })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  // Delete software

  const deleteSoftware = (i) => {
    let newSetSoftware = software;
    newSetSoftware.splice(i, 1);
    localStorage.setItem(
      defualtKey.software,
      JSON.stringify({ software: newSetSoftware })
    );
    setInc((parseInt(inc) + 1).toString());
  };

  return (
    <div className="p-4  bg-white">
      <h3>Software</h3>

      {software.map((value, index) => {
        return (
          <div>
            <Input
              type="text"
              defaultValue={value[0]}
              handleChange={(e) => {
                handleSoftwareChange(e, index);
              }}
            />
            <select
              name="stars"
              defaultValue={value[1]}
              className="form-control"
              onChange={(e) => {
                handleCircleChange(e, index);
              }}
            >
              <option
                selected="true"
                disabled="disabled"
                className="form-control"
              >
                Choose Value
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>

            {software.length > 1 ? (
              <div>
                <button
                  className="btn btn-danger mt-3"
                  onClick={() => {
                    deleteSoftware(index);
                  }}
                >
                  Delete
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <br />
          </div>
        );
      })}

      {software.length > 4 ? (
        <div></div>
      ) : (
        <button className="btn btn-success mb-2" onClick={addNewSoftware}>
          Add Software
        </button>
      )}

      <Discard setEditBox={setEditBox} />
    </div>
  );
}
